import { SET_CATEGORIES, SET_HASH, SET_CONFIG } from '../actionTypes';

const initialState = {
  user: null,
  hash: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_HASH: {
      const { hash } = action.payload;
      return {
        ...state,
        hash,
      };
    }
    case SET_CONFIG: {
      const { config } = action.payload;
      return {
        ...state,
        config,
      };
    }
    case SET_CATEGORIES: {
      const { categories } = action.payload;
      return {
        ...state,
        categories,
      };
    }
    default:
      return state;
  }
};
