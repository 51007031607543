import styled from 'styled-components';
import { Row, Col } from 'reactstrap';

export const ProductDescriptionList = styled.p`
  font-size: 13px;
  margin-bottom: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 60vw;
  overflow: hidden;
`;
export const ProductName = styled.h2`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
`;
export const ProductPrice = styled.span`
  font-weight: 500;
  margin-top: auto;
`;

export const ImageContainer = styled.div`
  padding: 15px 0 15px 15px;
  flex-shrink: 0;
  overflow: hidden;
`;

export const ProductBox = styled(Row)`
  cursor: pointer;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.3s ease-in-out;
  border-radius: 15px;
  margin: 0;
  min-height: 130px;
  width: 100%;
  border: solid thin ${props => props.config.productCardBackgroundColor};
  :hover {
    box-shadow: 0 5px 15px ${props => props.config.productCardShadowColor};
  }
`;

export const ProductGridContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  background-color: ${props => props.config.productCardBackgroundColor};
  color: ${props => props.config.textBaseColor};
  font-weight: bold;
  height: 100%;
  cursor: pointer;
  font-size: 14px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  border: solid thin ${props => props.config.productCardBackgroundColor};
  transition: box-shadow 0.3s ease-in-out;

  &:hover {
    box-shadow: 0 5px 15px ${props => props.config.productCardShadowColor};
  }

  @media (max-width: 600px) {
    font-size: 12px;
  }
`;

export const ProductGridName = styled.div`
  padding-top: 10px;
  padding-bottom: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    margin-bottom: 0;
  }
`;
export const ProductGridPrice = styled.div`
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    margin-bottom: 0;
  }
`;

export const ProductGridImage = styled.div`
  height: 100%;
`;

export const ColProductGrid = styled(Col)`
  margin-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  @media (max-width: 600px) {
    margin-top: 10px;
    padding-left: 5px;
    padding-right: 5px;
  }
`;
