import React, { Fragment } from 'react';

import { VariationName, OptionalName } from './style';
import { Col } from 'reactstrap';
import { toBlr, sumOptions } from '../../util';

const CartItem = ({ multiple = [], products = [] }) => {
  if (
    (!multiple || multiple.length === 0) &&
    (!products || products.length === 0)
  )
    return null;
  const elements = [];
  products.forEach(option => {
    elements.push(
      <Fragment key={`${option.productId}`}>
        <Col xs={8}>
          <OptionalName>
            {`${option.quantity}x ${option.productName}`}
          </OptionalName>
        </Col>
        {option.productPrice && (
          <Col xs={4} style={{ textAlign: 'left', fontSize: 12 }}>
            <OptionalName>{toBlr(option.productPrice)}</OptionalName>
          </Col>
        )}
      </Fragment>
    );
  });
  multiple.forEach(variation => {
    const options = variation.options.filter(
      ({ quantity, id }) =>
        quantity ||
        (variation.formData[id] && variation.formData[id].checked === true)
    );
    if (options.length === 0) return;
    elements.push(
      <Col xs={12} key={`${variation.id}_variation`}>
        <VariationName>
          <i style={{ float: 'left', fontSize: '0.7em' }}>{variation.title}</i>
        </VariationName>
      </Col>
    );
    options.forEach(option => {
      elements.push(
        <Fragment key={`${option.id}`}>
          <Col
            xs={variation.type === 1 ? 8 : 12}
            lg={variation.type === 1 ? 8 : 12}
          >
            <OptionalName>
              {`${
                variation.type === 1
                  ? option.quantity || 1
                  : `${option.quantity}/${sumOptions(options)}`
              }  ${option.name}`}
            </OptionalName>
          </Col>
          <Col
            xs={variation.type === 1 ? 4 : 12}
            lg={variation.type === 1 ? 4 : 12}
            style={{ textAlign: 'left', fontSize: 12 }}
          >
            {variation.type === 1 && (
              <OptionalName>{toBlr(option.price)}</OptionalName>
            )}
          </Col>
        </Fragment>
      );
    });
  });
  return elements;
};

export default CartItem;
