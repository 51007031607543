import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Card, CardBody, Row, Col, CardFooter } from 'reactstrap';
import { Button } from '../../components/button/style';
import { getColors } from '../../service';
import { OrderId, OrderSuccessTitle } from './style';

const Success = ({
  match: {
    params: { id },
  },
  history,
}) => {
  const [config, setConfig] = useState(null);

  const { state: stateLocation } = useLocation();

  useEffect(() => {
    async function fetchConfig() {
      const configs = await getColors();
      setConfig(configs);
    }
    if (stateLocation?.config) {
      setConfig(stateLocation.config);
    } else {
      fetchConfig();
    }
  }, []);

  return (
    <Card>
      <CardBody>
        <Row>
          <Col xs={12}>
            <OrderSuccessTitle config={config}>
              Seu pedido foi <br /> realizado com sucesso!
            </OrderSuccessTitle>
            <OrderId>Pedido #{id}</OrderId>
          </Col>
        </Row>
      </CardBody>
      <CardFooter>
        <Button
          onClick={() =>
            history.push(`/${process.env.REACT_APP_PROJECT}/orders`)
          }
          config={config}
        >
          Acompanhar pedido
        </Button>
      </CardFooter>
    </Card>
  );
};

export default Success;
