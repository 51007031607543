import React, { useMemo } from 'react';

import {
  colorIsReadableOnBackgroundWhite,
  formatCPF,
  formatPhone,
} from '../../util';
import { Button } from '../button/style';
import { Container, Line, Close } from './styles';

const ModalUser = ({
  isOpen,
  user,
  textBaseColor,
  goToOrders,
  logoutFromHeader,
  toggleModalUser,
  backgroundColor,
}) => {
  const color = useMemo(
    () =>
      colorIsReadableOnBackgroundWhite(textBaseColor)
        ? textBaseColor
        : '#000000',
    [textBaseColor]
  );
  return (
    <Container isActive={isOpen} color={color}>
      <Close onClick={toggleModalUser} color={backgroundColor} />
      <hr style={{ marginTop: 0 }} />
      <h1>{user.name}</h1>
      <hr />

      {user.mainPhone && (
        <Line>
          <p style={{ color }}>
            <strong>Telefone: </strong> {formatPhone(user.mainPhone)}
          </p>
        </Line>
      )}
      {user.email && (
        <Line>
          <p style={{ color }}>
            <strong>Email:</strong> {user.email}
          </p>
        </Line>
      )}
      {user.cpf && (
        <Line>
          <p style={{ color }}>
            <strong>CPF:</strong> {formatCPF(user.cpf)}
          </p>
        </Line>
      )}

      <hr />
      <Button
        onClick={() => {
          goToOrders();
          toggleModalUser();
        }}
      >
        Meus pedidos
      </Button>
      {!user.isTempUser && (
        <Button
          onClick={() => {
            logoutFromHeader();
            toggleModalUser();
          }}
          style={{ marginLeft: 10 }}
        >
          Sair
        </Button>
      )}
    </Container>
  );
};

export default ModalUser;
