/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { goToTop } from 'react-scrollable-anchor';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import {
  Logo,
  MobileMenu,
  Menu,
  ArrowDown,
  MenuClickable,
  HeaderStyled,
} from './style';
import { CloseIcon } from '../Categories/style';
import {
  debounce,
  formatCPF,
  formatPhone,
  getStorageUser,
  getUser,
  logout,
} from '../../util';
import { fetchUserDetail, getColors } from '../../service';
import { logoutUser, loginUser, setConfigs } from '../../redux/actions';
import ModalUser from '../../components/modalUser';
import { Line } from '../../components/modalUser/styles';
import { Overlay } from '../../util/style';

class Header extends Component {
  state = {
    mobileMenuIsOpen: false,
    modalUserIsOpen: false,
    shortHeader: false,
  };

  async componentDidMount() {
    await this.loadUser();
    try {
      const colors = await getColors();
      window.addEventListener('scroll', debounce(this.handleScroll, 50));
      this.props.setConfigs({ config: { ...colors } });
    } catch (e) {
      alert(`erro ao pegar configurações: ${e}`);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', debounce(this.handleScroll, 50));
  }

  handleScroll = () => {
    if (window.scrollY >= 120) {
      this.setState({
        shortHeader: true,
      });
    } else {
      this.setState({
        shortHeader: false,
      });
    }
  };

  loadUser = async () => {
    const user = getUser();
    if (!user) {
      const tempUser = getStorageUser();
      if (!tempUser) return;
      this.props.loginUser({ ...tempUser, isTempUser: true });
      return;
    }
    const customer = await fetchUserDetail({ token: user.token });
    this.props.loginUser({ ...customer, token: user.token });
  };

  logoutFromHeader = () => {
    logout();
    this.props.logoutUser();
    this.setState({ mobileMenuIsOpen: false });
    this.props.history.push(`/${process.env.REACT_APP_PROJECT}`);
  };

  loginUser = () => {
    this.setState({ mobileMenuIsOpen: false });
    this.props.history.push(`/${process.env.REACT_APP_PROJECT}/login`);
  };

  goToOrders = () => {
    this.setState({ mobileMenuIsOpen: false });
    this.props.history.push(`/${process.env.REACT_APP_PROJECT}/orders`);
  };

  toggleModalUser = () => {
    this.setState({ modalUserIsOpen: !this.state.modalUserIsOpen });
  };

  render() {
    const { config, user: customer } = this.props;
    if (!config) {
      return <div />;
    }
    return (
      <>
        <HeaderStyled
          config={this.props.config}
          isShort={this.state.shortHeader}
        >
          <Overlay
            className={this.state.modalUserIsOpen && 'active'}
            onClick={this.toggleModalUser}
          />

          <Logo
            src={config.merchantLogo}
            isShort={this.state.shortHeader}
            onClick={() => {
              const baseElement =
                window.document.getElementById('categoryContainer');
              if (baseElement) {
                baseElement.scrollTo(0, 0);
                goToTop();
              }
              this.props.history.push(`/${process.env.REACT_APP_PROJECT}`);
            }}
          />
          <MobileMenu
            size={30}
            color={this.props.config.textBaseColor}
            onClick={() =>
              this.setState(
                { mobileMenuIsOpen: !this.state.mobileMenuIsOpen },
                this.loadUser
              )
            }
          />
          {customer && (
            <Menu
              style={{
                color: this.props.config.textBaseColor,
              }}
              isOpen={this.state.modalUserIsOpen}
            >
              <MenuClickable
                onClick={this.toggleModalUser}
                color={this.props.config.textBaseColor}
              >
                <span style={{ fontSize: 16 }}>
                  Olá, {customer.name && customer.name.split(' ')[0]}
                </span>

                <ArrowDown
                  className={this.state.modalUserIsOpen && 'active'}
                  color={this.props.config.textBaseColor}
                />
              </MenuClickable>
              <ModalUser
                isOpen={this.state.modalUserIsOpen}
                user={customer}
                textBaseColor={this.props.config.textBaseColor}
                backgroundColor={this.props.config.bottomBackgroundColor}
                goToOrders={this.goToOrders}
                logoutFromHeader={this.logoutFromHeader}
                toggleModalUser={this.toggleModalUser}
              />
            </Menu>
          )}
          {config.hasLogin === 'NO' && !customer && (
            <Menu style={{ color: this.props.config.textBaseColor }}>
              <span
                onClick={this.goToOrders}
                style={{ cursor: 'pointer', fontSize: 16, fontWeight: 'bold' }}
              >
                Meus pedidos
              </span>
            </Menu>
          )}
          {config.hasLogin === 'YES' && !customer && (
            <Menu style={{ color: this.props.config.textBaseColor }}>
              <span
                onClick={!customer ? this.loginUser : this.logoutFromHeader}
                style={{ cursor: 'pointer', fontSize: 16, fontWeight: 'bold' }}
              >
                Entrar
              </span>
            </Menu>
          )}
        </HeaderStyled>
        <Modal
          size="lg"
          isOpen={this.state.mobileMenuIsOpen}
          toggle={() => this.setState({ mobileMenuIsOpen: false })}
        >
          <ModalHeader>
            OPDV
            <CloseIcon
              onClick={() => this.setState({ mobileMenuIsOpen: false })}
            >
              Fechar
            </CloseIcon>
          </ModalHeader>
          <ModalBody>
            {customer && (
              <>
                <hr style={{ marginTop: 0 }} />
                <h1
                  style={{ textAlign: 'center', fontSize: 24, fontWeight: 400 }}
                >
                  {customer.name}
                </h1>
                <hr />

                {customer.mainPhone && (
                  <Line>
                    <p style={{ color: 'black' }}>
                      <strong>Telefone: </strong>{' '}
                      {formatPhone(customer.mainPhone)}
                    </p>
                  </Line>
                )}
                {customer.email && (
                  <Line>
                    <p style={{ color: 'black' }}>
                      <strong>Email:</strong> {customer.email}
                    </p>
                  </Line>
                )}
                {customer.cpf && (
                  <Line>
                    <p style={{ color: 'black' }}>
                      <strong>CPF:</strong> {formatCPF(customer.cpf)}
                    </p>
                  </Line>
                )}

                <hr />
              </>
            )}
            <ul style={{ padding: '0 20px' }}>
              {(customer || config.hasLogin === 'NO') && (
                <li>
                  <a onClick={this.goToOrders} style={{ color: 'black' }}>
                    Meus Pedidos
                  </a>
                </li>
              )}
              {config.hasLogin === 'YES' && (
                <li>
                  <a
                    onClick={!customer ? this.loginUser : this.logoutFromHeader}
                  >
                    {!customer ? 'Entrar' : 'Sair'}
                  </a>
                </li>
              )}
            </ul>
          </ModalBody>
        </Modal>
      </>
    );
  }
}
const mapStateToProps = state => {
  const {
    config: { config },
    user: { user },
  } = state;
  return { user, config };
};
export default connect(mapStateToProps, { logoutUser, loginUser, setConfigs })(
  Header
);
