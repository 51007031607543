import React, { Component } from 'react';
import { connect } from 'react-redux';
import CreditCardInput from 'react-credit-card-input';
import { confirmAlert } from 'react-confirm-alert';
import { detect } from 'detect-browser';
import { toast } from 'react-toastify';
import {
  CardBody,
  Row,
  Col,
  Modal,
  Input,
  FormGroup,
  FormText,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
  CardHeader,
} from 'reactstrap';
import InputMask from 'react-input-mask';

import moment from 'moment';
import { FaArrowLeft } from 'react-icons/fa';
import {
  CartCheckout,
  RowDesktop,
  CartContainer,
  ColPayments,
  ButtonPaymentOptions,
} from './style';
import {
  getUser,
  getCart,
  getSelectedStore,
  getSelectedAddress,
  setTempIdOrders,
  setStorageUser,
  getStorageUser,
  validateCpf,
  findElementPosition,
  isMobile,
  debounce,
  isEmpty,
} from '../../util';
import {
  removeFromCart,
  clearCart,
  setConfigs,
  setFromCookie,
  applyPromoCode,
  loginUser,
} from '../../redux/actions';
import {
  fetchUserDetail,
  fetchStorePayments,
  fetchCards,
  fetchAddressInformation,
  createNewAddress,
  sendOrder,
  sendMetadata,
  createCard,
  fetchOpenHours,
  getColors,
} from '../../service';
import Loading from '../../components/loading';
import CartBody from '../../components/cart/body';
import CartFooter from '../../components/cart/footer';
import CurrencyInput from '../../components/currency-input';
import { NewAddress } from '../../components/address';
import { Button } from '../../components/button/style';
import { createOrder as parserOrder } from '../../util/order';
import Icons from './Icons';
import { trackEvent } from '../../util/track';

const browser = detect();

class Checkout extends Component {
  state = {
    customer: {
      cpf: '',
      customerId: 0,
      email: '',
      name: '',
      mainPhone: '',
    },
    cards: [],
    loadingCep: false,
    loading: true,
    newCard: false,
    change: 0,
    dates: [],
    height: 500,
    selectedPayment: {},
    isTakeAway: false,
    payTakeAway: false,
    isDelivery: true,
    loadingDeliveryInformation: false,
    addressInformation: {},
    paymentModal: false,
    loadingPayments: false,
    deliveryIsCoverage: false,
    orderErrorMessage: '',
    creatingCardLoading: false,
    deliveryFee: null,
    validatingPromoCode: false,
    cardError: {},
    paymentsOptions: {},
    payNow: false,
    deliveryStoreId: null,
    scheduledTime: null,
    openScheduleTimeModal: false,
    tempDayOfWeek: null,
    card: {},
    selectedStore: {},
    loadAddressFromCep: false,
    dayOfWeek: null,
    openHours1: [],
    openHours2: [],
    observation: '',
    hasLogin: null,
    cpfRequired: null,
    finishOrderButtonWasClickedDisabled: false,
  };

  async componentDidMount() {
    const store = getSelectedStore();
    if (!this.props.config) {
      const colors = await getColors();
      this.props.setConfigs({ config: { ...colors } });
    }
    const {
      config: { hasLogin, cpfRequired },
    } = this.props;
    const user = hasLogin === 'YES' ? getUser() : getStorageUser();
    this.setState({ hasLogin, cpfRequired });
    if (user) this.setState({ customer: user });

    if (hasLogin === 'YES' && !user) {
      this.props.history.push(`/${process.env.REACT_APP_PROJECT}/login`);
      return;
    }

    const { openHours1, openHours2 } = await fetchOpenHours({
      token: process.env.REACT_APP_TOKEN,
      storeId: store.id || store.storeId,
      hasLogin,
      user: getUser(),
    });

    const openHours1Replaced = openHours1
      ? openHours1.map(open => open && open.replace('|', ' - '))
      : null;
    const openHours2Replaced = openHours2
      ? openHours2.map(open => open && open.replace('|', ' - '))
      : null;

    this.setState({
      openHours1: openHours1Replaced,
      openHours2: openHours2Replaced,
      paymentModal: !!isMobile(),
    });

    if (!getSelectedAddress() && getSelectedStore().type === 'delivery') {
      this.props.history.push('/');
      return;
    }
    if (!getSelectedStore()) {
      this.props.history.push('/');
      return;
    }
    // this.props.changeStore({ store: getSelectedStore() });

    window.addEventListener('resize', debounce(this.handleResize, 100));
    this.loadData();
    const colors = await getColors();
    this.props.setConfigs({ config: { ...colors } });
    this.createDates();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', debounce(this.handleResize, 100));
    this.props.applyPromoCode({ code: null, percent: 0, promocodeId: null });
  }

  scrollToPayment = () => {
    if (isMobile()) {
      const mobileBody = document.querySelector('.mobile-body');
      const modalMobileHeaderHeight = 60;
      mobileBody.scrollTo(
        0,
        findElementPosition(mobileBody.querySelector('.payment-methods')) -
          modalMobileHeaderHeight
      );
    } else {
      const headerHeight = 120;
      window.scroll(
        0,
        findElementPosition(document.querySelector('.payment-methods')) -
          headerHeight
      );
    }
  };

  focusOnInput = className => {
    if (isMobile()) {
      document.querySelector('.mobile-body').scrollTo(0, 0);
      document.querySelector(`.${className}-mobile`).focus();
    } else {
      window.scroll(0, 0);
      document.querySelector(`.${className}`).focus();
    }
  };

  handleResize = () => {
    this.setState({
      paymentModal: !!isMobile(),
    });
  };

  createDates = () => {
    const dates = [
      moment().add(
        parseInt(this.props.config.hoursAfterShceduled, 10),
        'hours'
      ),
    ];

    const vacationTimes = [
      '1702',
      '1802',
      '1902',
      '2002',
      '2102',
      '2202',
      '2302',
    ];

    if (
      vacationTimes.includes(moment().format('DDMM')) &&
      parseInt(process.env.REACT_APP_MERCHANT_ID, 10) === 31
    ) {
      dates[0] = moment('24/02/2021', 'DD/MM/YYYY');
    }
    const getVacationTime = daysToAdd => {
      const newDate = moment(dates[0]).add(daysToAdd, 'days');
      const formated = newDate.format('DDMM');
      const index = vacationTimes.findIndex(vacation => vacation === formated);
      if (
        index !== -1 &&
        parseInt(process.env.REACT_APP_MERCHANT_ID, 10) === 31
      ) {
        newDate.add(vacationTimes.length + 1 - daysToAdd + index, 'days');
      }

      return newDate;
    };
    dates.push(getVacationTime(1));
    dates.push(getVacationTime(2));
    dates.push(getVacationTime(3));
    dates.push(getVacationTime(4));
    if (parseInt(process.env.REACT_APP_MERCHANT_ID, 10) === 149) {
      const diffDate = moment('12/06/2021', 'DD/MM/YYYY').diff(
        dates[dates.length - 1],
        'days'
      );
      for (let i = 0; i <= diffDate; i += 1) {
        dates.push(getVacationTime(4 + i + 1));
      }
    }
    if (parseInt(process.env.REACT_APP_MERCHANT_ID, 10) === 156) {
      const diffDate = moment('09/05/2021', 'DD/MM/YYYY').diff(
        dates[dates.length - 1],
        'days'
      );
      for (let i = 0; i <= diffDate; i += 1) {
        dates.push(getVacationTime(4 + i + 1));
      }
    }

    if (this.props.config.scheduledAllowToday === 'YES') {
      dates.unshift(moment());
    }
    this.setState({ dates });
  };

  loadData = async () => {
    const user = getUser();
    const { hasLogin } = this.state;
    const cookieCart = getCart();
    if (cookieCart) {
      this.props.setFromCookie(cookieCart);
    }
    if (hasLogin === 'YES') {
      const customer = await fetchUserDetail({
        token: user ? user.token : process.env.REACT_APP_TOKEN,
      });
      if (!isEmpty(customer)) {
        this.setState({ customer });
      }
    }
    const selectedStore = getSelectedStore();
    const [paymentsOptions, { cards }] = await Promise.all([
      fetchStorePayments(selectedStore.storeId || selectedStore.id),
      fetchCards(
        user ? user.token : process.env.REACT_APP_TOKEN,
        getSelectedStore().id || getSelectedStore().storeId,
        hasLogin
      ),
    ]);

    this.setState({
      loading: false,
      selectedStore,
      paymentsOptions,
      cards,
      isTakeAway: selectedStore.type.toLowerCase() === 'takeaway',
      isDelivery: selectedStore.type.toLowerCase() === 'delivery',
    });
    if (selectedStore.type.toLowerCase() === 'takeaway') {
      this.setState({ deliveryFee: 0 });
    } else {
      this.fetchDeliveryData();
    }
  };

  fetchDeliveryData = async () => {
    const { isDelivery } = this.state;
    const { cart } = this.props;
    if (isDelivery) {
      this.setState(
        {
          deliveryStoreId: getSelectedStore().id || getSelectedStore().storeId,
          deliveryFee:
            cart.deliveryFee === undefined
              ? getSelectedStore().deliveryFee
              : cart.deliveryFee,
          deliveryIsCoverage: true,
          orderErrorMessage: null,
        },
        () => console.log('deliveryFee: ', this.state.deliveryFee)
      );
    }
  };

  openPaymentOptions = async () => {
    this.setState({
      paymentModal: true,
    });
  };

  payNow = () => {
    trackEvent({
      name: 'payWithCard',
      data: { user: getUser() ? getUser() : { name: 'Anônimo' } },
    });
    this.setState({ payNow: true, payTakeAway: false }, () => {
      this.scrollToPayment();
    });
  };

  payTakeAway = () => {
    trackEvent({
      name: 'payWithOnDelivery',
      data: { user: getUser() ? getUser() : { name: 'Anônimo' } },
    });
    this.setState({ payTakeAway: true, payNow: false }, () => {
      this.scrollToPayment();
    });
  };

  handleChangeCardInformation =
    cardField =>
    ({ target: { value } }) => {
      if (cardField === 'cvv' && `${value}`.length > 4) return;
      const { card, cardError } = this.state;
      card[cardField] = value;
      cardError[cardField] = false;
      this.setState({ card, cardError });
    };

  removeProduct = async product => {
    confirmAlert({
      title: 'Remover produto',
      message: 'Deseja remover este produto do carrinho?',
      buttons: [
        {
          label: 'Sim',
          onClick: () => {
            this.props.removeFromCart(product.cartId);
          },
        },
        {
          label: 'Não',
          onClick: () => {},
        },
      ],
    });
  };

  createNewCreditCard = async () => {
    const { card } = this.state;
    const user = getUser();
    this.setState({ creatingCardLoading: true });
    const createdCard = await createCard(
      user ? user.token : process.env.REACT_APP_TOKEN,
      {
        cardNumber: card.number.split(' ').join(''),
        cardExpirationDate: card.expiry.split(' ').join(''),
      }
    );
    const { cards } = await fetchCards(
      user ? user.token : process.env.REACT_APP_TOKEN,
      getSelectedStore().id || getSelectedStore().storeId
    );

    this.setState({
      cards,
      creatingCardLoading: false,
      newCard: false,
      cardError: {},
      card: {
        id: createdCard.id,
      },
    });
  };

  finishOrder = async () => {
    const {
      customer,
      deliveryFee,
      change,
      card,
      payNow,
      isTakeAway,
      selectedStore,
      cpfRequired,
      hasLogin,
    } = this.state;
    const sourceId = sessionStorage.getItem('sourceId');
    const externalId = sessionStorage.getItem('externalId');

    if (hasLogin === 'NO') {
      if (!customer.name) {
        this.focusOnInput('customerName');
        alert('Nome obrigatório.');
        return;
      }

      if (!customer.mainPhone) {
        this.focusOnInput('customerMainPhone');
        alert('Telefone obrigatório.');
        return;
      }

      if (cpfRequired === 'YES' && !validateCpf(customer.cpf)) {
        this.focusOnInput('customerCPF');
        alert('o CPF não é válido');
        return;
      }
    }

    this.setState({ loadingOrder: true });

    const { cart } = this.props;
    const user = getUser();
    if (payNow && !card.id && hasLogin === 'YES') {
      const createdCard = await createCard(
        user ? user.token : process.env.REACT_APP_TOKEN,
        {
          cardNumber: card.number.split(' ').join(''),
          cardExpirationDate: card.expiry.split(' ').join(''),
        }
      );
      card.id = createdCard.id;
    }

    const selectedAddress = getSelectedAddress();
    if (selectedAddress) {
      delete selectedAddress.canEditStreet;
    }
    const order = parserOrder({
      cartProducts: cart.products,
      discount: cart.discount,
      sourceId,
      externalId,
      scheduled: this.props.config.hasScheduledOrder === 'YES',
      dayOfWeek: this.state.dayOfWeek,
      timeOfDay: this.state.selectUtcTime,
      discountId: -1,
      promocodeId: cart.promocodeId,
      address: selectedAddress,
      customer: {
        id: customer.id,
        name: customer.name,
        cpf: customer.cpf,
        email: customer.email,
        mainPhone: customer.mainPhone.replace(/\D/g, ''),
      },
      customerId: customer.id,
      deliveryFee,
      storeId: this.state.deliveryStoreId || selectedStore.id,
      change: change * 100,
      isTakeAway: selectedStore.type.toLowerCase() === 'takeaway',
      payment: this.state.selectedPayment,
      observation: this.state.observation,
    });
    if (payNow) {
      order.card = {
        ...card,
        id: card.id,
        cvv: card.cvv,
      };
    }
    if (change && change * 100 < order.total) {
      alert('Valor pago em dinheiro precisa ser igual ou maior ao pedido.');
      this.setState({ loadingOrder: false });
      return;
    }
    if (isTakeAway) {
      this.setState({ paymentModal: false });
      confirmAlert({
        closeOnEscape: false,
        closeOnClickOutside: false,
        title: 'Buscar pedido na loja',
        message: `Você confirma que vai retirar o pedido na loja ${selectedStore.name} - ${selectedStore.address}?`,
        buttons: [
          {
            label: 'Não',
            onClick: () => {
              this.setState({
                loadingOrder: false,
                loading: false,
                change: null,
                payNow: false,
                payTakeAway: false,
                selectedPayment: { id: null },
                paymentModal: false,
                // paymentsOptions: {},
              });
            },
          },
          {
            label: 'Sim',
            onClick: () => {
              if (isMobile()) {
                this.setState({ paymentModal: true });
              }
              this.createOrder(order);
            },
          },
        ],
      });
    } else {
      this.createOrder(order);
    }
  };

  finishOrderDisable = () => {
    if (!isMobile()) return;
    const { payNow, payTakeAway, dayOfWeek } = this.state;
    const modalMobileHeaderHeight = 80;
    const mobileBody = document.querySelector('.mobile-body');

    this.setState({
      finishOrderButtonWasClickedDisabled: true,
    });
    if (this.props.config.hoursAfterShceduled && !dayOfWeek) {
      mobileBody.scrollTo(
        0,
        findElementPosition(mobileBody.querySelector('.scheduledRow')) - 50
      );
      return;
    }
    if (!payNow && !payTakeAway) {
      mobileBody.scrollTo(
        0,
        findElementPosition(mobileBody.querySelector('.paymentRow')) -
          modalMobileHeaderHeight
      );
    }
  };

  createOrder = async order => {
    const { selectedStore, customer } = this.state;
    const {
      config: { hasLogin },
    } = this.props;
    const user = getUser();
    if (hasLogin === 'NO') {
      setStorageUser({ ...customer, token: process.env.REACT_APP_TOKEN });
      this.props.loginUser({
        ...customer,
        token: process.env.REACT_APP_TOKEN,
        isTempUser: true,
      });
    }
    const result = await sendOrder(
      this.state.deliveryStoreId || selectedStore.id,
      order,
      user ? user.token : process.env.REACT_APP_TOKEN,
      hasLogin
    );
    if (result.id) {
      trackEvent({
        name: 'Purchase',
        data: { currency: 'BRL', value: order.total / 100 },
      });

      await sendMetadata(result.id, {
        platform: browser.name,
        os: browser.os,
        platformVersion: browser.version,
        appVersion: process.env.VERSION,
        log: JSON.stringify(this.state),
      });
      this.props.clearCart();

      setTempIdOrders(result.id, hasLogin === 'YES');
      this.props.history.push(`/success/${result.id}`, {
        config: this.props.config,
      });
    } else {
      trackEvent({
        name: 'completedOrderWithError',
        data: { user: user || { name: 'Anônimo' }, error: result.erro },
      });

      this.setState({
        loadingOrder: false,
        loading: false,
        payNow: false,
        change: null,
        payTakeAway: false,
        selectedPayment: { id: null },
        paymentModal: false,
        paymentsOptions: {},
      });

      if (result.erro) {
        this.loadData();
        alert(result.erro);
      } else {
        this.loadData();
        alert('Erro ao finalizar pedido, tente novamente.');
      }
    }
  };

  createAddress = async e => {
    e.preventDefault();
    this.setState({ loadingCep: true });
    const user = getUser();
    const address = {
      ...this.state.addressInformation,
      cep: this.state.addressInformation.zip,
    };
    delete address.zip;
    delete address.canEditStreet;
    await createNewAddress({
      body: {
        address,
      },
      token: user ? user.token : process.env.REACT_APP_TOKEN,
    });
    this.setState({
      loadingCep: false,
      newAddressPopup: false,
      loading: true,
    });
    const customer = await fetchUserDetail({
      token: user ? user.token : process.env.REACT_APP_TOKEN,
    });
    if (!isEmpty(customer)) {
      this.setState({ customer });
    }
    this.setState({ loading: false, addressSelectModal: true });
  };

  loadAddressFromCep = async zipCode => {
    this.setState({ loadAddressFromCep: true });
    const addressInformation = await fetchAddressInformation(zipCode)
      .then(addressInformation => ({
        ...addressInformation,
        canEditStreet:
          !addressInformation.street || addressInformation.street.trim() === '',
        number: '',
        complement: '',
      }))
      .catch(() => ({
        error: 'Cep não encontrado',
      }));

    this.setState({ loadAddressFromCep: false, addressInformation });
  };

  convertToLocalDate = ({ index, date }) => {
    const day = moment()
      .startOf('week')
      .add(index || 1, 'day')
      .format('DD');
    const month = moment().format('MM');
    const year = moment().format('YYYY');

    const convertedDate = moment(
      `${day}/${month}/${year} ${date} +0000`,
      'DD/MM/YYYY HH:mm Z'
    );
    convertedDate.day(day);
    convertedDate.month(month);
    return convertedDate;
  };

  openHourSelection = date => {
    this.setState({ openScheduleTimeModal: true, tempDayOfWeek: date });
  };

  selectTime = () => {
    if (!this.state.selectUtcTime) {
      toast.warn('Você precisa selecionar um horário');
      return;
    }
    this.setState(
      {
        dayOfWeek: this.state.tempDayOfWeek.format('DD/MM'),
        openScheduleTimeModal: false,
      },
      () => {
        this.scrollToPayment();
      }
    );
  };

  drawPossibleTimes = () => {
    const { tempDayOfWeek, openHours1 } = this.state;
    if (!tempDayOfWeek || !openHours1) return null;
    const start = this.convertToLocalDate({
      index: tempDayOfWeek.weekday(),
      date: openHours1[tempDayOfWeek.weekday()].split('-')[0],
    });

    const end = this.convertToLocalDate({
      index: tempDayOfWeek.weekday(),
      date: openHours1[tempDayOfWeek.weekday()].split('-')[1],
    });

    const isToday = tempDayOfWeek.format('DD/MM') === moment().format('DD/MM');

    if (
      isToday &&
      parseInt(start.format('H')) >= parseInt(moment().local().format('H'))
    ) {
      const toAdd =
        parseInt(moment().local().format('H')) -
        parseInt(start.format('H')) +
        1;
      if (toAdd > 0) {
        start.add(toAdd, 'hours');
      }
    }
    const possibleTimes = [];
    while (end.diff(start, 'hours') >= 0) {
      possibleTimes.push(moment(start));
      start.add('1', 'hours');
    }
    if (possibleTimes.length === 0)
      return (
        <Label>
          Não existe mais horário disponível para o dia atual, por favor,
          selecione outro dia
        </Label>
      );

    return possibleTimes.map(time => (
      <FormGroup check key={`time_${time.format('HH')}`}>
        <Input
          type="radio"
          name="selectedTime"
          id={time.format('HH')}
          onClick={() => this.setState({ selectUtcTime: time.utc() })}
        />
        <Label check for={time.format('HH')}>{`${time.format('HH')}h`}</Label>
      </FormGroup>
    ));
  };

  handleChange = ({ target: { name, value } }) => {
    this.setState(prev => ({
      customer: {
        ...prev.customer,
        [name]: value,
      },
    }));
  };

  handleChangeOnlyNumber = ({ target: { name, value } }) => {
    this.setState(prev => ({
      customer: {
        ...prev.customer,
        [name]: value.replace(/\D/g, ''),
      },
    }));
  };

  render() {
    const {
      discount,
      loadingDeliveryInformation,
      isTakeAway,
      cardError,
      payTakeAway,
      loading,
      paymentModal,
      loadingPayments,
      paymentsOptions,
      change,
      creatingCardLoading,
      selectedPayment,
      loadingOrder,
      openHours2,
      cards,
      card,
      openHours1,
      dates,
      payNow,
      customer,
      finishOrderButtonWasClickedDisabled,
    } = this.state;
    const { cart } = this.props;
    const canNotFinishOrder =
      (payNow && (cardError.number || cardError.cvv || cardError.expiry)) ||
      (!payNow && !selectedPayment.id) ||
      (payNow && !card.id && (!card.cvv || card.cvv === '')) ||
      (payNow && card.id && (!card.cvv || card.cvv === ''));
    if (!this.props.config) {
      return <Loading message="Carregando informações..." />;
    }

    const translate = {
      0: 'Domingo',
      1: 'Segunda-feira',
      2: 'Terça-feira',
      3: 'Quarta-feira',
      4: 'Quinta-feira',
      5: 'Sexta-feira',
      6: 'Sabado',
    };
    const payBody = (
      <>
        {!loadingPayments && !loadingOrder && (
          <>
            {this.props.config.hoursAfterShceduled && (
              <Row className="scheduledRow">
                <Col xs={12} style={{ margin: '10px 0' }}>
                  Qual o melhor dia para{' '}
                  {this.state.isDelivery ? 'entregar' : 'retirar na loja'} suas
                  compras?
                </Col>
                <div
                  style={{
                    display: isMobile() ? 'flex' : '',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: ' 100%',
                  }}
                >
                  {dates
                    .filter(
                      date =>
                        !this.state.openHours1 ||
                        this.state.openHours1[date.weekday()]
                    )
                    .map(date => (
                      <Button
                        key={`${date.weekday()}`}
                        outline
                        onClick={() => this.openHourSelection(date)}
                        style={{
                          width: 250,
                          margin: 4,
                          color:
                            this.state.dayOfWeek === date.format('DD/MM')
                              ? this.props.config.bottomTextColor
                              : '',
                          backgroundColor:
                            this.state.dayOfWeek === date.format('DD/MM')
                              ? this.props.config.bottomBackgroundColor
                              : '',
                        }}
                        disabled={this.state.dayOfWeek === date.format('DD/MM')}
                      >
                        <div>
                          <div>{date.format('DD/MM')}</div>
                          <div>{translate[date.weekday()]}</div>
                          {this.state.selectUtcTime &&
                            this.state.dayOfWeek === date.format('DD/MM') && (
                              <div>
                                {`${this.state.selectUtcTime
                                  .local()
                                  .format('HH')}h`}
                              </div>
                            )}
                          {this.state.dayOfWeek !== date.format('DD/MM') && (
                            <div>
                              {this.state.openHours1 &&
                              this.state.openHours1[date.weekday()]
                                ? `${this.convertToLocalDate({
                                    index: date.weekday(),
                                    date: openHours1[date.weekday()].split(
                                      '-'
                                    )[0],
                                  }).format('HH:mm -')}
                            ${this.convertToLocalDate({
                              index: date.weekday(),
                              date: openHours1[date.weekday()].split('-')[1],
                            }).format(' HH:mm')}`
                                : '12:00 - 18:00'}
                              {this.state.openHours2 &&
                              this.state.openHours2[date.weekday()]
                                ? `e ${this.convertToLocalDate({
                                    index: date.weekday(),
                                    date: openHours2[date.weekday()].split(
                                      '-'
                                    )[0],
                                  }).format('HH:mm -')}
                          ${this.convertToLocalDate({
                            index: date.weekday(),
                            date: openHours2[date.weekday()].split('-')[1],
                          }).format(' HH:mm')}`
                                : ''}
                            </div>
                          )}
                        </div>
                      </Button>
                    ))}
                </div>
              </Row>
            )}
            <Row
              style={{
                marginTop: 12,
                display:
                  this.state.dayOfWeek === null &&
                  this.props.config.hoursAfterShceduled
                    ? 'none'
                    : 'flex',
              }}
              className="paymentRow"
            >
              <Col xs={12}>
                <span>Como você deseja pagar este pedido?</span>
              </Col>
              <ColPayments xs={12}>
                {paymentsOptions.appPayment && (
                  <ButtonPaymentOptions
                    onClick={this.payNow}
                    outline
                    style={{
                      color: payNow ? this.props.config.bottomTextColor : '',
                      backgroundColor: payNow
                        ? this.props.config.bottomBackgroundColor
                        : '',
                    }}
                    config={this.props.config}
                  >
                    Pagar agora (Online)
                  </ButtonPaymentOptions>
                )}
                {paymentsOptions.payments &&
                  paymentsOptions.payments.length > 0 && (
                    <ButtonPaymentOptions
                      outline
                      config={this.props.config}
                      style={{
                        width: 150,
                        color: payTakeAway
                          ? this.props.config.bottomTextColor
                          : '',
                        backgroundColor: payTakeAway
                          ? this.props.config.bottomBackgroundColor
                          : '',
                      }}
                      onClick={this.payTakeAway}
                    >
                      Pagar na {isTakeAway ? 'retirada' : 'entrega'}
                    </ButtonPaymentOptions>
                  )}
              </ColPayments>
            </Row>
          </>
        )}
        <div className="payment-methods" style={{ marginTop: 28 }}>
          {payTakeAway && !loadingOrder && (
            <>
              <Row>
                <Col xs={12}>
                  <span>Selecione a forma de pagamento</span>
                </Col>
                {selectedPayment.methodKey === 'dinheiro' && (
                  <Col xs={12}>
                    <span>Troco para quanto?</span>
                    <CurrencyInput
                      name="change"
                      value={change}
                      onChange={(event, value) =>
                        this.setState({ change: value })
                      }
                    />
                    <FormText>
                      Informe o total do pagamento em dinheiro, por exemplo R$
                      100,00
                    </FormText>
                  </Col>
                )}
              </Row>
              <Row>
                {paymentsOptions.payments
                  .filter(({ publishedApp, publishedAppTakeAway }) => {
                    if (isTakeAway) {
                      return publishedAppTakeAway;
                    }
                    return publishedApp;
                  })
                  .map(payment => {
                    const disabled = selectedPayment.id === payment.id;
                    return (
                      <Col
                        xs={12}
                        lg={6}
                        key={`payment_${payment.id}`}
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <Button
                          disabled={disabled}
                          outline
                          style={{
                            width: '90%',
                            marginTop: 4,
                            opacity: 1,
                            color: disabled
                              ? this.props.config.bottomTextColor
                              : '',
                            backgroundColor: disabled
                              ? this.props.config.bottomBackgroundColor
                              : '',
                          }}
                          onClick={() => {
                            this.setState({ selectedPayment: payment }, () =>
                              isMobile()
                                ? document
                                    .querySelector('.mobile-body')
                                    .scrollTo(
                                      0,
                                      document.querySelector('.mobile-body')
                                        .scrollHeight
                                    )
                                : window.scrollTo(0, document.body.scrollHeight)
                            );
                          }}
                        >
                          {payment.name}
                        </Button>
                      </Col>
                    );
                  })}
              </Row>
            </>
          )}
          {payNow && !loadingOrder && cards.length > 0 && (
            <Row>
              <Col xs={12}>Cartões cadastrados</Col>
              <Col xs={12}>
                <hr />
              </Col>
            </Row>
          )}
          {payNow &&
            !loadingOrder &&
            cards.map(cardView => (
              <Row key={`card_${cardView.cardLastDigits}`}>
                <Col xs={4} lg={2}>
                  <FormGroup check style={{ textAlign: 'left' }}>
                    <Label check>
                      <Input
                        type="radio"
                        checked={cardView.id === card.id}
                        style={{ width: 20, height: 20 }}
                        onChange={() => {}}
                        onClick={({ target: { value, checked } }) => {
                          const sameCard = card.id === cardView.id;
                          this.setState({
                            card: {
                              id: sameCard ? null : cardView.id,
                              cvv: sameCard ? null : cardView.cvv,
                            },
                          });
                        }}
                      />
                      <img
                        style={{ width: 30, marginLeft: 8 }}
                        src={Icons[cardView.brand]}
                        alt="Cartão de crédito"
                      />
                    </Label>
                  </FormGroup>
                </Col>

                <Col xs={6} lg={3} style={{ textAlign: 'left' }}>
                  {cardView.cardLastDigits}
                </Col>
                {cardView.id === card.id && (
                  <Col lg={7} xs={12}>
                    <FormGroup>
                      <Label>Informe o código de segurança</Label>
                      <Input
                        style={{ width: 150 }}
                        type="number"
                        maxLength={4}
                        value={card.cvv}
                        onChange={this.handleChangeCardInformation('cvv')}
                      />
                    </FormGroup>
                  </Col>
                )}
                <Col xs={12}>
                  <hr />
                </Col>
              </Row>
            ))}
          {payNow && !loadingOrder && cards.length > 0 && (
            <Row>
              <Col xs={12}>
                <Button
                  onClick={() => this.setState({ newCard: true })}
                  outline
                >
                  Cadastrar novo cartão
                </Button>
              </Col>
            </Row>
          )}
          {!loadingOrder && payNow && cards.length === 0 && (
            <Row style={{ marginTop: 28 }}>
              <Col xs={12}>
                <span>Informe os dados do cartão de crédito</span>
              </Col>
              <Col xs={12}>
                <CreditCardInput
                  cardNumberInputProps={{
                    disabled: cardError.expiry || cardError.cvv,
                    onError: () =>
                      this.setState({
                        cardError: {
                          ...this.state.cardError,
                          number: true,
                        },
                      }),
                    onChange: this.handleChangeCardInformation('number'),
                  }}
                  cardExpiryInputProps={{
                    disabled: cardError.number || cardError.cvv,
                    onError: () =>
                      this.setState({
                        cardError: {
                          ...this.state.cardError,
                          expiry: true,
                        },
                      }),
                    onChange: this.handleChangeCardInformation('expiry'),
                  }}
                  cardCVCInputProps={{
                    disabled: cardError.expiry || cardError.number,
                    maxLength: 4,
                    onError: () =>
                      this.setState({
                        cardError: {
                          ...this.state.cardError,
                          cvv: true,
                        },
                      }),
                    onChange: this.handleChangeCardInformation('cvv'),
                  }}
                  fieldClassName="input"
                  customTextLabels={{
                    invalidCardNumber: 'Número do cartão é inválido',
                    expiryError: {
                      invalidExpiryDate: 'Data de vencimento',
                      monthOutOfRange: 'Mês do vencimento, entre 01 e 12',
                      yearOutOfRange: 'Ano do vencimento do cartão',
                    },
                    invalidCvc: 'Código de segurança inválido',
                    cardNumberPlaceholder: 'Número do cartão',
                    expiryPlaceholder: 'MM/AA',
                    cvcPlaceholder: 'COD',
                  }}
                />
              </Col>
            </Row>
          )}
        </div>
      </>
    );

    if (loading) {
      return <Loading message="Carregando informações..." />;
    }
    return (
      <>
        <Modal isOpen={this.state.openScheduleTimeModal} size="lg">
          <ModalHeader>Qual é o horário desejado?</ModalHeader>
          <ModalBody>{this.drawPossibleTimes()}</ModalBody>
          <ModalFooter>
            <Button
              outline
              color="danger"
              onClick={() =>
                this.setState({
                  openScheduleTimeModal: false,
                  selectUtcTime: null,
                })
              }
            >
              Cancelar
            </Button>
            <Button onClick={this.selectTime}>Selecionar horário</Button>
          </ModalFooter>
        </Modal>
        <NewAddress
          createAddress={this.createAddress}
          addressInformation={this.state.addressInformation}
          loadingCep={this.state.loadingCep}
          onChange={field => value => {
            this.setState({ [field]: value });
            if (
              field === 'cep' &&
              value.replace('_', '').replace('-', '').length === 8
            ) {
              const cep = value.replace('_', '').replace('-', '');
              this.loadAddressFromCep(cep);
            }
            if (field !== 'cep') {
              this.setState({
                addressInformation: {
                  ...this.state.addressInformation,
                  [field]: value,
                },
              });
            }
          }}
          toggle={() => this.setState({ newAddressPopup: false })}
          isOpen={this.state.newAddressPopup}
        />

        <Modal isOpen={this.state.newCard} size="sm">
          <ModalBody>
            {creatingCardLoading && <Loading message="Salvando novo cartão" />}
            {!creatingCardLoading && (
              <Row style={{ marginTop: 28 }}>
                <Col xs={12}>
                  <span>Informe os dados do cartão de crédito</span>
                </Col>
                <Col xs={12}>
                  <CreditCardInput
                    cardCVCInputProps={{ type: 'hidden' }}
                    cardNumberInputProps={{
                      disabled: cardError.expiry || cardError.cvv,
                      onError: () =>
                        this.setState({
                          cardError: {
                            ...this.state.cardError,
                            number: true,
                          },
                        }),
                      onChange: this.handleChangeCardInformation('number'),
                    }}
                    cardExpiryInputProps={{
                      disabled: cardError.number || cardError.cvv,
                      onError: () =>
                        this.setState({
                          cardError: {
                            ...this.state.cardError,
                            expiry: true,
                          },
                        }),
                      onChange: this.handleChangeCardInformation('expiry'),
                    }}
                    fieldClassName="input"
                    customTextLabels={{
                      invalidCardNumber: 'Número do cartão é inválido',
                      expiryError: {
                        invalidExpiryDate: 'Data de vencimento',
                        monthOutOfRange: 'Mês do vencimento, entre 01 e 12',
                        yearOutOfRange: 'Ano do vencimento do cartão',
                      },
                      cardNumberPlaceholder: 'Número do cartão',
                      expiryPlaceholder: 'MM/AA',
                    }}
                  />
                </Col>
              </Row>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              outline
              disabled={creatingCardLoading}
              color="danger"
              onClick={() => this.setState({ newCard: false, cardError: {} })}
            >
              Cancelar
            </Button>
            <Button
              disabled={
                creatingCardLoading || cardError.expiry || cardError.number
              }
              onClick={this.createNewCreditCard}
            >
              Salvar
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={paymentModal}
          style={{ borderRadius: 0 }}
          size="lg"
          fade={false}
        >
          <ModalBody
            className="mobile-body"
            style={{ scrollBehavior: 'smooth' }}
          >
            {loadingPayments && (
              <Loading message="Carregando formas de pagamento" />
            )}
            {!loadingPayments && !loadingOrder && (
              <header
                style={{
                  position: 'sticky',
                  top: -20,
                  zIndex: 10,
                  backgroundColor: 'white',
                }}
              >
                <div>
                  <hr />
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <FaArrowLeft
                      onClick={() =>
                        this.props.history.push(
                          `/${process.env.REACT_APP_PROJECT}/`
                        )
                      }
                      color={this.props.config.textCategoryColor}
                    />
                    <span
                      style={{
                        fontWeight: 'bold',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                      }}
                    >
                      <h1
                        style={{
                          color: this.props.config.textCategoryColor,
                          fontWeight: 'bold',
                          fontSize: '1.25rem',
                          marginBottom: 0,
                        }}
                      >
                        Finalize seu pedido
                      </h1>
                    </span>
                  </div>
                  <hr />
                </div>
              </header>
            )}
            {this.state.hasLogin === 'NO' && (
              <Row style={{ marginTop: 20 }}>
                <Col xs={6}>
                  <FormGroup>
                    <Label>Nome *</Label>
                    <Input
                      name="name"
                      type="text"
                      value={customer.name}
                      onChange={this.handleChange}
                      className="customerName-mobile"
                    />
                  </FormGroup>
                </Col>
                <Col xs={6}>
                  <FormGroup>
                    <Label>Telefone *</Label>
                    <InputMask
                      mask="(99)99999.9999"
                      value={customer.mainPhone}
                      name="mainPhone"
                      onChange={this.handleChangeOnlyNumber}
                      className="customerMainPhone-mobile"
                    >
                      {inputProps => <Input {...inputProps} />}
                    </InputMask>
                  </FormGroup>
                </Col>

                {this.state.cpfRequired === 'YES' && (
                  <Col xs={12}>
                    <FormGroup>
                      <Label>CPF *</Label>
                      <InputMask
                        mask="999.999.999-99"
                        value={customer.cpf}
                        name="cpf"
                        onChange={this.handleChangeOnlyNumber}
                        className="customerCPF-mobile"
                      >
                        {inputProps => <Input {...inputProps} />}
                      </InputMask>
                    </FormGroup>
                  </Col>
                )}
              </Row>
            )}
            <h4 style={{ fontWeight: 500, fontSize: '1rem' }}>
              Endereço de {isTakeAway ? 'retirada' : 'entrega'}
            </h4>
            <Row style={{ marginBottom: '1rem' }}>
              <Col xs={12} style={{ fontWeight: 400, fontSize: '0.75rem' }}>
                {isTakeAway
                  ? getSelectedStore().address
                  : `${getSelectedAddress().street} ${
                      getSelectedAddress().number
                    } ${getSelectedAddress().complement}`}
              </Col>
              {!isTakeAway && (
                <Col
                  xs={12}
                  style={{ fontWeight: 400, fontSize: '0.75rem' }}
                >{`${getSelectedAddress().city}/${
                  getSelectedAddress().state
                }`}</Col>
              )}
            </Row>
            {getSelectedStore().deliveryForecast && (
              <>
                <h4 style={{ fontWeight: 500, fontSize: '1rem' }}>
                  Previsão de {isTakeAway ? 'retirada' : 'entrega'}
                </h4>
                <Row style={{ marginBottom: 10 }}>
                  <Col xs={12} style={{ fontWeight: 400, fontSize: '0.75rem' }}>
                    {moment()
                      .local()
                      .add(
                        'minutes',
                        isTakeAway
                          ? getSelectedStore().deliveryForecast / 2
                          : getSelectedStore().deliveryForecast
                      )
                      .format('DD/MM/YYYY HH:mm')}
                  </Col>
                </Row>
              </>
            )}
            {!loadingPayments && !loadingOrder && (
              <>
                <FormGroup style={{ marginTop: 20 }}>
                  <Label>Observações do pedido</Label>
                  <Input
                    type="textarea"
                    value={this.state.observation}
                    maxLength="256"
                    onChange={({ target: { value } }) =>
                      this.setState({ observation: value })
                    }
                    name="observation"
                    style={{ resize: 'none' }}
                  />
                  {this.state.observation && (
                    <FormText>Máximo 256 caracteres</FormText>
                  )}
                </FormGroup>
                <CartFooter
                  cart={cart}
                  discount={discount}
                  deliveryFee={
                    cart.deliveryFee !== undefined
                      ? cart.deliveryFee
                      : this.state.deliveryFee
                  }
                  applyPromoCode={this.props.applyPromoCode}
                  colorPromoCode={this.props.config.textCategoryColor}
                />
              </>
            )}
            {!loadingOrder && payBody}
            {loadingOrder && <Loading message="Criando pedido.." />}
          </ModalBody>
          {!loadingOrder && (
            <ModalFooter
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Button
                onClick={() => {
                  if (canNotFinishOrder) {
                    this.finishOrderDisable();
                  } else {
                    this.finishOrder();
                  }
                }}
                style={{
                  color: this.props.config.bottomTextColor,
                  backgroundColor: this.props.config.bottomBackgroundColor,
                  opacity: canNotFinishOrder ? 0.65 : 1,
                }}
              >
                Finalizar pedido
              </Button>
              {payNow &&
                canNotFinishOrder &&
                finishOrderButtonWasClickedDisabled && (
                  <FormText style={{ textAlign: 'center' }}>
                    <span style={{ color: 'red', fontSize: 12 }}>
                      Você precisa escolher ou preencher os dados do seu cartão
                      de crédito
                    </span>
                  </FormText>
                )}
              {!selectedPayment.id &&
                !payNow &&
                finishOrderButtonWasClickedDisabled && (
                  <FormText style={{ textAlign: 'center' }}>
                    <span style={{ color: 'red', fontSize: 12 }}>
                      Você precisa selecionar ao menos uma forma de pagamento
                    </span>
                  </FormText>
                )}
            </ModalFooter>
          )}
        </Modal>

        <CartCheckout id="checkout-container">
          {loadingOrder && <Loading message="Criando pedido.." />}
          {!loadingOrder && (
            <CardBody style={{ padding: 0, overflow: 'hidden' }}>
              {loadingDeliveryInformation && (
                <Loading message="Carregando informações de delivery" />
              )}
              {!loadingDeliveryInformation && (
                <Row style={{ margin: 0, height: '100%' }}>
                  <Col
                    xs={12}
                    lg={7}
                    style={{ height: '100%' }}
                    className="order-1 order-lg-0"
                  >
                    <h1
                      style={{
                        color: this.props.config.textCategoryColor,
                        fontWeight: 'bold',
                        marginBottom: 24,
                      }}
                    >
                      Finalize seu pedido
                    </h1>
                    <h4 style={{ fontWeight: 500 }}>
                      Endereço de {isTakeAway ? 'retirada' : 'entrega'}
                    </h4>
                    <Row>
                      <Col xs={12} style={{ fontWeight: 400 }}>
                        {isTakeAway
                          ? getSelectedStore().address
                          : `${getSelectedAddress().street} ${
                              getSelectedAddress().number
                            } ${getSelectedAddress().complement}`}
                      </Col>
                      {!isTakeAway && (
                        <Col xs={12} style={{ fontWeight: 400 }}>{`${
                          getSelectedAddress().city
                        }/${getSelectedAddress().state}`}</Col>
                      )}
                    </Row>
                    {getSelectedStore().deliveryForecast && (
                      <>
                        <hr />
                        <h4 style={{ fontWeight: 500, fontSize: 20 }}>
                          Previsão de {isTakeAway ? 'retirada' : 'entrega'}
                        </h4>
                        <Row style={{ marginBottom: 10 }}>
                          <Col xs={12} style={{ fontWeight: 400 }}>
                            {moment()
                              .local()
                              .add(
                                'minutes',
                                isTakeAway
                                  ? getSelectedStore().deliveryForecast / 2
                                  : getSelectedStore().deliveryForecast
                              )
                              .format('DD/MM/YYYY HH:mm')}
                          </Col>
                        </Row>
                      </>
                    )}
                    {this.state.hasLogin === 'NO' && (
                      <Row style={{ marginTop: 20 }}>
                        <Col xs={6}>
                          <FormGroup>
                            <Label>Nome *</Label>
                            <Input
                              name="name"
                              type="text"
                              value={customer.name}
                              onChange={this.handleChange}
                              className="customerName"
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={6}>
                          <FormGroup>
                            <Label>Telefone *</Label>
                            <InputMask
                              mask="(99)99999.9999"
                              value={customer.mainPhone}
                              name="mainPhone"
                              onChange={this.handleChangeOnlyNumber}
                              className="customerMainPhone"
                            >
                              {inputProps => <Input {...inputProps} />}
                            </InputMask>
                          </FormGroup>
                        </Col>

                        {this.state.cpfRequired === 'YES' && (
                          <Col xs={6}>
                            <FormGroup>
                              <Label>CPF *</Label>
                              <InputMask
                                mask="999.999.999-99"
                                value={customer.cpf}
                                name="cpf"
                                onChange={this.handleChangeOnlyNumber}
                                className="customerCPF"
                              >
                                {inputProps => <Input {...inputProps} />}
                              </InputMask>
                            </FormGroup>
                          </Col>
                        )}
                      </Row>
                    )}
                    <RowDesktop style={{ padding: 15 }}>
                      {payBody}
                      <div>
                        <FormGroup style={{ marginTop: 20 }}>
                          <Label>Observações do pedido</Label>
                          <Input
                            type="textarea"
                            value={this.state.observation}
                            maxLength="256"
                            onChange={({ target: { value } }) =>
                              this.setState({ observation: value })
                            }
                            name="observation"
                            style={{ resize: 'none' }}
                          />
                          {this.state.observation && (
                            <FormText>Máximo 256 caracteres</FormText>
                          )}
                        </FormGroup>
                        <Button
                          style={{
                            marginTop: 80,
                            width: '100%',
                            color: this.props.config.bottomTextColor,
                            backgroundColor:
                              this.props.config.bottomBackgroundColor,
                          }}
                          onClick={this.finishOrder}
                          disabled={canNotFinishOrder || loadingOrder}
                        >
                          Finalizar pedido
                        </Button>
                      </div>
                    </RowDesktop>
                  </Col>
                  <Col
                    xs={12}
                    lg={5}
                    style={{
                      height: '100%',
                      paddingBottom: 15,
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                    className="order-0 order-lg-1"
                  >
                    <CartContainer>
                      <CardHeader>
                        <h4 className="card-title text-black">
                          <small className="small">Seu pedido em</small> <br />
                          {getSelectedStore().name}
                        </h4>
                      </CardHeader>
                      <div
                        style={{
                          overflowY: 'overlay',
                          overflowX: 'hidden',
                          padding: 30,
                        }}
                      >
                        <CartBody
                          cart={cart}
                          removeAction={this.removeProduct}
                          editAction={({ id }) =>
                            this.props.history.push(`/${id}/edit`)
                          }
                        />
                      </div>
                      <CartFooter
                        cart={cart}
                        deliveryFee={
                          cart.deliveryFee !== undefined
                            ? cart.deliveryFee
                            : this.state.deliveryFee
                        }
                        applyPromoCode={this.props.applyPromoCode}
                        colorPromoCode={this.props.config.textCategoryColor}
                      />
                    </CartContainer>
                  </Col>
                </Row>
              )}
            </CardBody>
          )}
        </CartCheckout>
      </>
    );
  }
}
const mapStateToProps = state => {
  const {
    config: { config },
    cart: { cart },
    user: { user },
  } = state;
  return { cart, config, user };
};
export default connect(mapStateToProps, {
  setFromCookie,
  clearCart,
  setConfigs,
  //  changeStore,
  applyPromoCode,
  removeFromCart,
  loginUser,
})(Checkout);
