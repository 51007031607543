import styled from 'styled-components';

import { Tab } from 'react-tabs';
import { Card, CardHeader } from 'reactstrap';

export const OrderTab = styled(Tab)`
  display: inline-block;
  margin-top: 20px;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  width: 50%;
  cursor: pointer;
  color: ${({ selected, config }) => {
    return selected ? 'black' : config.textBaseColor;
  }};
`;

export const HeaderSmallLabel = styled.span`
  font-size: 0.87em;
  @media (max-width: 1000px) {
    font-size: calc(8px + 1.5vmin);
  }
`;

export const HeaderBigLabel = styled.span`
  font-size: 1em;
  @media (max-width: 1000px) {
    font-size: calc(12px + 1.5vmin);
  }
`;

export const CardOrder = styled(Card)`
  width: 800px;
  margin-bottom: 20px;

  @media (max-width: 1000px) {
    width: 100%;
  }
`;

export const CardHeaderOrder = styled(CardHeader)`
  display: flex;
  justify-content: space-between;
  @media (max-width: 400px) {
    flex-direction: column;
    align-items: center;
  }
`;
