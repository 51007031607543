import styled from 'styled-components';
import { IoMdMenu } from 'react-icons/io';

export const HeaderStyled = styled.header`
  display: flex;
  color: white;
  height: 120px;
  padding: 20px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 15;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  border-bottom: 1px solid ${props => props.config?.headerBottomColor};
  background-color: ${props => props.config?.headerBackgroundColor};

  @media (max-width: 999px) {
    position: ${props => (props.isShort ? 'fixed' : '')};
    height: ${props => (props.isShort ? '70px' : '120px')};
  }
`;

export const Logo = styled.img`
  transform: scale(0.6);
  cursor: pointer;
  max-height: 150px;
  @media (max-width: 1000px) {
    transform: scale(0.5);
    display: ${props => (props.isShort ? 'none' : 'block')};
    position: relative;
    top: -20px;
  }
`;

export const MobileMenu = styled(IoMdMenu)`
  display: none;
  position: absolute;
  right: 25px;
  @media (max-width: 1000px) {
    display: block;
  }
`;

export const Menu = styled.div`
  position: absolute;
  display: flex;
  font-size: calc(8px + 2vmin);
  padding: 0 15px;
  right: 45px;
  @media (max-width: 1000px) {
    display: none;
  }
`;

export const MenuClickable = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: bold;
  user-select: none;
  padding: 0 5px;
  border-radius: 5px;
  transition: 0.2s;
`;

export const ArrowDown = styled.div`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid ${props => props.color};
  margin-left: 5px;
  transition: 0.3s;
  position: relative;
  top: 1px;
  margin-left: 7px;

  &.active {
    transform: rotate(180deg);
  }
`;
