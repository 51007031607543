import React, { useState } from 'react';
import {
  ModalBody,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  FormText,
} from 'reactstrap';
import { FaMinusCircle, FaPlusCircle } from 'react-icons/fa';
import { MdOutlineImage } from 'react-icons/md';

import {
  HintHeader,
  FullHeigthDiv,
  Footer,
  ButtonOpenImageOptional,
} from './style';
import {
  toBlr,
  countSelectedOptions,
  calcProductTotalValue,
  getUser,
  isMobile,
} from '../../util';
import ImageViewer from '../ImageViewer';
import CartResume from './resume';
import { Button } from '../button/style';
import { trackEvent } from '../../util/track';

const Step = ({
  step,
  simpleItem = false,
  wizardSteps,
  subtractOptional,
  sumOptional,
  moveNext,
  wizardIndex,
  moveBack,
  updateObservation,
  product,
  updateWizardSteps,
  subtractProduct,
  sumProduct,
  categoryTextColor,
}) => {
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [srcImageViewer, setSrcImageViewer] = useState('');

  if (!step || simpleItem) {
    trackEvent({
      name: 'openProductResume',
      data: {
        productId: product.id,
        productName: product.name,
        user: getUser(),
      },
    });

    return (
      <CartResume
        product={product}
        subtractProduct={subtractProduct}
        sumProduct={sumProduct}
        updateObservation={updateObservation}
        wizardSteps={wizardSteps}
        categoryTextColor={categoryTextColor}
      />
    );
  }
  const countSelectedOptionals = countSelectedOptions(step);
  trackEvent({
    name: 'navigateToStep',
    data: {
      step: step.title,
      user: getUser(),
    },
  });

  const onOpenImageViewer = image => {
    setViewerIsOpen(true);
    setSrcImageViewer(
      `https://d26aylt6qzro7d.cloudfront.net/${process.env.REACT_APP_MERCHANT_ID}/${image}`
    );
  };

  const onCloseImageViewer = () => {
    setViewerIsOpen(false);
    setSrcImageViewer('');
  };

  return (
    <>
      <ImageViewer
        isOpen={viewerIsOpen}
        src={srcImageViewer}
        onClose={onCloseImageViewer}
      />
      <ModalBody style={{ overflow: 'overlay' }}>
        <Row>
          <Col xs={12}>
            <HintHeader>
              {step.min === 0 ? (
                `Escolha uma opção (min: ${step.min} max: ${step.max})`
              ) : (
                <b>
                  Obrigatório, máximo: {step.max}, mínimo: {step.min}
                </b>
              )}
            </HintHeader>
          </Col>
        </Row>
        <Row>
          <Col xs={12} style={{ fontWeight: 'bold' }}>
            {step.title}
          </Col>
          <Col xs={12}>
            <h3>{step.description}</h3>
          </Col>
        </Row>
        <FullHeigthDiv
          style={{
            zIndex: 400,
            width: '100%',
            height: '100%',
          }}
        >
          {step.options
            .sort(
              (a, b) => a.position - b.position || a.name.localeCompare(b.name)
            )
            .map(option => {
              const { name, image, id, description, price, quantity } = option;
              return (
                <Row key={option.id}>
                  <Col xs={12}>
                    <Row style={{ padding: 2 }}>
                      <Col xs={12}>
                        {step.optionType === 'MULTIPLE' && (
                          <Row>
                            <Col
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <FaMinusCircle
                                  color="red"
                                  size={24}
                                  style={{ cursor: 'pointer' }}
                                  onClick={subtractOptional({
                                    option,
                                    step,
                                  })}
                                />
                                <span style={{ margin: '0.5em' }}>
                                  {quantity}
                                </span>
                                <FaPlusCircle
                                  color="green"
                                  size={24}
                                  style={{ cursor: 'pointer' }}
                                  onClick={sumOptional({ option, step })}
                                />
                              </div>
                              <span style={{ fontWeight: 600, marginLeft: 10 }}>
                                {name}
                              </span>
                              {price ? (
                                <span style={{ marginLeft: 5, marginRight: 5 }}>
                                  {price > 0 ? toBlr(price) : ''}
                                </span>
                              ) : (
                                <div />
                              )}
                              {image && (
                                <div style={{ height: 24, marginLeft: 5 }}>
                                  <ButtonOpenImageOptional
                                    categoryTextColor={categoryTextColor}
                                    onClick={() => onOpenImageViewer(image)}
                                  >
                                    <MdOutlineImage
                                      size={18}
                                      color={categoryTextColor}
                                    />
                                    {isMobile() ? (
                                      ''
                                    ) : (
                                      <span
                                        style={{
                                          fontWeight: 500,
                                          fontSize: 12,
                                          marginLeft: 5,
                                          color: categoryTextColor,
                                        }}
                                      >
                                        Ver imagem
                                      </span>
                                    )}
                                  </ButtonOpenImageOptional>
                                </div>
                              )}
                            </Col>
                          </Row>
                        )}
                        {step.optionType === 'SINGLE' && (
                          <FormGroup
                            check
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <Label check>
                              <Input
                                checked={
                                  (step.formData[id] &&
                                    step.formData[id].checked) ||
                                  false
                                }
                                type="radio"
                                onChange={newValue => {}}
                                onClick={({ target: { checked, value } }) => {
                                  if (!step.formData[id]) {
                                    step.formData[id] = { checked: false };
                                  }
                                  step.formData[id].checked =
                                    !step.formData[id].checked;
                                  step.options
                                    .filter(option => option.id !== id)
                                    .forEach(item => {
                                      step.formData[item.id] = {
                                        checked: false,
                                      };
                                    });
                                  const wizardStepsToState = wizardSteps.map(
                                    stepWizard => {
                                      if (step.id !== stepWizard.id)
                                        return stepWizard;
                                      return {
                                        ...stepWizard,
                                        options: stepWizard.options.map(
                                          optionStep => {
                                            if (optionStep.id !== option.id)
                                              return optionStep;
                                            return {
                                              ...optionStep,
                                              quantity: null,
                                            };
                                          }
                                        ),
                                      };
                                    }
                                  );
                                  updateWizardSteps(wizardStepsToState);
                                }}
                              />
                              <span style={{ fontWeight: 600 }}>{name}</span>
                            </Label>
                            {price ? (
                              <span style={{ marginLeft: 5, marginRight: 5 }}>
                                {price > 0 ? toBlr(price) : ''}
                              </span>
                            ) : (
                              <div />
                            )}
                            {image && (
                              <div style={{ height: 24, marginLeft: 5 }}>
                                <ButtonOpenImageOptional
                                  categoryTextColor={categoryTextColor}
                                  onClick={() => onOpenImageViewer(image)}
                                >
                                  <MdOutlineImage
                                    size={18}
                                    style={{ marginRight: 5 }}
                                    color={categoryTextColor}
                                  />
                                  <span
                                    style={{
                                      fontWeight: 500,
                                      fontSize: 12,
                                      color: categoryTextColor,
                                    }}
                                  >
                                    Ver imagem
                                  </span>
                                </ButtonOpenImageOptional>
                              </div>
                            )}
                          </FormGroup>
                        )}
                      </Col>
                      <Col xs={12} style={{ fontWeight: 'lighter' }}>
                        {description}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              );
            })}
        </FullHeigthDiv>
      </ModalBody>
      <Footer>
        <div style={{ width: '30%' }}>
          <Row>
            <Col sm={12}>Total item:</Col>
            <Col sm={12}>
              {calcProductTotalValue({
                cartProduct: {
                  ...product,
                  wizardSteps,
                },
                format: true,
              })}
            </Col>
          </Row>
        </div>
        <div style={{ textAlign: 'right' }}>
          {wizardIndex > 0 && (
            <Button outline onClick={moveBack} style={{ marginRight: 4 }}>
              {'<<'} Voltar
            </Button>
          )}
          <Button
            disabled={step.min > 0 && countSelectedOptionals < step.min}
            onClick={moveNext}
          >
            Avançar {'>>'}
          </Button>
          {step.min > 0 && countSelectedOptionals < step.min && (
            <div>
              <FormText color="red">
                Você precisa selecionar ao menos um item para continuar
              </FormText>
            </div>
          )}
        </div>
      </Footer>
    </>
  );
};
export default Step;
