import React, { Component } from 'react';
import { configureAnchors } from 'react-scrollable-anchor';
import { Router, Route } from 'react-router-dom';
import ReactGA from 'react-ga';
import 'react-tabs/style/react-tabs.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactPixel from 'react-facebook-pixel';
import TagManager from 'react-gtm-module';
import { isMobile } from 'react-device-detect';

import { Provider } from 'react-redux';
import store from './redux/store';

import './App.css';
import Header from './views/Header';
import Categories from './views/Categories';
import Login from './views/Login';
import Checkout from './views/Checkout';
import Orders from './views/Orders';
import history from './history';
import CreateAccount from './views/CreateAccount';
import Forgot from './views/ForgotPassword';
import Success from './views/Success';
import FastRegister from './views/FastRegister';
import { trackEvent } from './util/track';

configureAnchors({ offset: -120, scrollDuration: 200 });

class App extends Component {
  componentDidMount() {
    const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
    const options = {
      autoConfig: false, // set pixel's autoConfig
      debug: true, // enable logs
    };
    if (process.env.REACT_APP_GOOGLE_ANALYTICS) {
      ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
    if (process.env.REACT_APP_PIXEL) {
      ReactPixel.init(process.env.REACT_APP_PIXEL, advancedMatching, options);
      ReactPixel.pageView();
    }
    if (process.env.REACT_APP_GOOGLE) {
      const tagManagerArgs = {
        gtmId: `${process.env.REACT_APP_GOOGLE}`,
      };
      TagManager.initialize(tagManagerArgs);
    }
    history.listen(location => {
      ReactGA.pageview(window.location.pathname + window.location.search);
      trackEvent({
        name: 'pageChange',
        data: { newPage: location.pathname },
      });
    });
  }

  componentWillUnmount() {
    localStorage.removeItem('sourceId');
    localStorage.removeItem('externalId');
  }

  render() {
    return (
      <Provider store={store}>
        <div className="App">
          <ToastContainer position={isMobile ? 'bottom-center' : 'top-right'} />
          <div
            style={{
              height: '100%',
              width: '100%',
              position: 'relative',
              marginTop: 120,
            }}
          >
            <Router history={history}>
              <Header history={history} />
              <div style={{ overflowX: 'hidden' }}>
                <Route exact path="/" component={Categories} />
                <Route
                  exact
                  path={`/${process.env.REACT_APP_PROJECT}`}
                  component={Categories}
                />
                <Route exact path="/fidelidade" component={FastRegister} />
                <Route
                  exact
                  path={`/${process.env.REACT_APP_PROJECT}/fidelidade`}
                  component={FastRegister}
                />

                <Route exact path="/:id/edit" component={Categories} />
                <Route
                  exact
                  path={`/${process.env.REACT_APP_PROJECT}/:id/edit`}
                  component={Categories}
                />

                <Route exact path="/open/:sku" component={Categories} />
                <Route
                  exact
                  path={`/${process.env.REACT_APP_PROJECT}/open/:sku`}
                  component={Categories}
                />

                <Route exact path="/login" component={Login} />
                <Route
                  exact
                  path={`/${process.env.REACT_APP_PROJECT}/login`}
                  component={Login}
                />

                <Route exact path="/checkout" component={Checkout} />
                <Route
                  exact
                  path={`/${process.env.REACT_APP_PROJECT}/checkout`}
                  component={Checkout}
                />

                <Route exact path="/orders" component={Orders} />
                <Route
                  exact
                  path={`/${process.env.REACT_APP_PROJECT}/orders`}
                  component={Orders}
                />
                <Route exact path="/create" component={CreateAccount} />
                <Route
                  exact
                  path={`/${process.env.REACT_APP_PROJECT}/create`}
                  component={CreateAccount}
                />

                <Route exact path="/password" component={Forgot} />
                <Route
                  exact
                  path={`/${process.env.REACT_APP_PROJECT}/password`}
                  component={Forgot}
                />

                <Route exact path="/success/:id" component={Success} />
                <Route
                  exact
                  path={`/${process.env.REACT_APP_PROJECT}/success/:id`}
                  component={Success}
                />
              </div>
            </Router>
          </div>
        </div>
      </Provider>
    );
  }
}

export default App;
