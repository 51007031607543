import React, { Fragment } from "react";
import {
  Row,
  Col,
  Input,
  Modal,
  ModalHeader,
  FormGroup,
  Label,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import InputMask from "react-input-mask";
import { CloseIcon } from "../../views/Checkout/style";
import Loading from "../loading";

export const NewAddress = ({
  onChange,
  isOpen,
  editStreet,
  toggle,
  loadingCep,
  createAddress,
  addressInformation,
}) => (
  <Modal size={"lg"} isOpen={isOpen} toggle={toggle}>
    <form onSubmit={createAddress}>
      <ModalHeader style={{ textAlign: "center" }}>
        <CloseIcon onClick={toggle}>Fechar</CloseIcon>
        Novo endereço
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col xs={12}>
            <FormGroup>
              <Label>Informe seu CEP</Label>
              <InputMask
                disabled={loadingCep}
                mask="99999-999"
                required
                onChange={({ target: { value: cep } }) => onChange("cep")(cep)}
              >
                {(inputProps) => (
                  <Input
                    {...inputProps}
                    disabled={loadingCep}
                    placeholder="CEP"
                    required
                  />
                )}
              </InputMask>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          {loadingCep && (
            <Col xs={12}>
              <Loading />
            </Col>
          )}
          {!loadingCep && addressInformation.error && (
            <Col xs={12}>
              <span style={{ color: "red" }}>{addressInformation.error}</span>
            </Col>
          )}
          {!loadingCep && addressInformation.zip && (
            <Fragment>
              <Col lg={8} sm={12}>
                <FormGroup>
                  <Label>Endereço</Label>
                  <Input
                    value={addressInformation.street}
                    placeholder="Endereço"
                    required
                    onChange={({ target: { value: street } }) =>
                      onChange("street")(street)
                    }
                    disabled={!addressInformation.canEditStreet}
                  />
                </FormGroup>
              </Col>
              <Col lg={4} sm={12}>
                <FormGroup>
                  <Label>Número</Label>
                  <Input
                    value={addressInformation.number}
                    placeholder="Número"
                    required
                    onChange={({ target: { value: number } }) =>
                      onChange("number")(number)
                    }
                  />
                </FormGroup>
              </Col>
              <Col lg={8} sm={12}>
                <FormGroup>
                  <Label>Complemento</Label>
                  <Input
                    value={addressInformation.complement}
                    onChange={({ target: { value: complement } }) =>
                      onChange("complement")(complement)
                    }
                    placeholder="Número"
                  />
                </FormGroup>
              </Col>
            </Fragment>
          )}
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button type="submit" disabled={!addressInformation.number}>
          Salvar
        </Button>
      </ModalFooter>
    </form>
  </Modal>
);
