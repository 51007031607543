import React from 'react';
import { isMobile } from '../../util';
import { Container } from './styles';

const ImageViewer = ({ onClose, isOpen, src }) => (
  <>
    <Container isOpen={isOpen} onClick={onClose}>
      <div>
        <p>
          {isMobile() ? 'Toque' : 'Clique'} em qualquer lugar da tela para
          fechar a imagem
        </p>
        <img src={src} alt="opcional do produto" />
      </div>
    </Container>
  </>
);

export default ImageViewer;
