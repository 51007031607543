import styled from 'styled-components';
import { Col, Button as ButtonReact, Card, Row, CardBody } from 'reactstrap';
import { config } from '../../config';

export const ItemWrapper = styled(Col)`
  position: relative !important;
  display: inline-block !important;
  vertical-align: text-top !important;
  height: auto !important;
  max-width: 360px !important;
  width: 100% !important;
  margin: 35px 12px 0 12px !important;
  padding: 16px !important;
  border: solid 1px #e8e8e8 !important;
  border-radius: 25px !important;
  background-color: #fff !important;
  min-height: 370px !important;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.08) !important;
  &::after {
    position: absolute !important;
    display: block !important;
    width: 60px !important;
    height: 60px !important;
    top: 65px !important;
    left: 50% !important;
    border-radius: 100% !important;
    content: '\f00c' !important;
    transform: translateX(-50%) !important;
    color: #fff !important;
    font-size: 30px !important;
    line-height: 60px !important;
    text-align: center !important;
    pointer-events: none !important;
    opacity: 0 !important;
  }
  &::last-child {
    margin-right: 0 !important;
  }
  @media (max-width: 1000px) {
    max-width: 100% !important;
    width: 90% !important;
  }
`;

export const CloseIcon = styled.span`
  color: red;
  cursor: pointer;
  @media (max-width: 1000px) {
    display: inline;
    cursor: pointer;
    float: right;
  }
`;

export const CategoryBigName = styled.h1`
  margin: 0 30px;
  color: ${props => props.config.textCategoryColor};
  text-align: left;
  font-weight: 400;
  font-size: 30px;

  @media (max-width: 1000px) {
    margin: 0 15px;
  }
`;

export const CategorySmallName = styled.h3`
  text-overflow: ellipsis;
  /* display: box; box? */
  margin: 45px 0 10px 30px;
  text-align: left;
  color: ${props => props.config.textCategoryColor};
  font-size: 20px;
  font-weight: bold;
  @media (max-width: 1000px) {
    margin: 0px !important;
    padding: 15px;
  }
`;
export const CategoryContainer = styled.div`
  width: 100%;
  @media (max-width: 1000px) {
    padding: 5px;
    padding-bottom: 40px;
  }
`;
export const CategoryMobileFilter = styled(Row)`
  flex-grow: 1;
  position: fixed;
  top: 180px;
  margin-left: 0;
  z-index: 5;
  width: 100%;
  display: block;
  text-align: left;
`;
export const CategoryMenu = styled(Col)`
  padding: 8px;
  @media (max-width: 1000px) {
    display: none;
  }
`;

export const ColCart = styled(Col)`
  padding: 15px 30px;
  position: fixed;
  right: 0;
  top: 170;
  height: 100%;
  @media (max-width: 1000px) {
    display: none;
  }
`;

export const CartDesktopContainer = styled(Card)`
  box-shadow: 0px 4px 8px rgb(0 0 0 / 20%);
  border: 1px solid #f7f7f7;
  max-height: calc(100vh - 205px);
  padding: 0;
  margin-top: 46px;
  @media (max-width: 999px) {
    display: none;
  }
`;

export const CartMobileContainer = styled(Card)`
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 200;
  right: 0;
  @media (max-width: 999px) {
    display: inline;
  }
`;

export const ItemImageWrapper = styled.div`
  width: auto !important;
  height: auto !important;
  & > img {
    max-width: 286px !important;
    height: 183px !important;
    border-radius: 20px !important;
  }
`;

export const ItemInfoWrapper = styled.div`
  width: auto !important;
  height: auto !important;
`;
export const ItemInfoTitle = styled.div`
  font-size: 20px !important;
  margin-top: 23px !important;
  & > p {
    display: inline-block !important;
    color: ${props => props.color || config.colors.secondary}!important;
    line-height: 20px !important;
  }
  & > span {
    display: inline-block !important;
    color: #323232 !important;
    font-size: 15px !important;
  }
`;

export const ItemInfoDescription = styled.div`
  font-size: 13px !important;
  margin-top: 17px !important;
  margin-bottom: 20px !important;
  color: #828282 !important;
  & > p {
    line-height: 22px !important;
    color: #828282 !important;
  }
`;
export const ItemInfoAction = styled.div`
  position: relative !important;
  & > div {
    align-items: center !important;
  }
`;
export const Button = styled(ButtonReact)`
  color: ${props => config.colors.textColor}!important;
  cursor: pointer !important;
  background-color: ${props =>
    props.color ||
    config.listColors[props.index] ||
    config.listColors[0]}!important;
  border-radius: 20.5px !important;
  font-size: 15px !important;
  padding: 13px 16px !important;
  display: inline-block !important;
  margin-left: 1px !important;
  transition: 0.3s !important;
  &:hover {
    -webkit-filter: brightness(130%) !important;
    transition: 1s !important;
    background-color: ${props => config.colors.primaryColor}!important;
  }
`;
export const ItemContainerWrapper = styled.div`
  margin-bottom: 65px !important;
  color: ${props =>
    props.color ||
    config.listColors[props.index] ||
    config.listColors[0]}!important;
  text-align: center !important;
  & > * p {
    text-align: left;
    margin-left: 100px;
  }
`;
export const ProductList = styled(Col)`
  margin-bottom: 180px !important;
  @media (max-width: 1000px) {
    margin-bottom: 180px !important;
  }
`;

export const MobileCol = styled(Col)`
  display: none;
  z-index: 4;
  @media (max-width: 1000px) {
    display: flex;
    width: 100%;
    position: sticky;
    top: 22px;
  }
`;

export const BodyNoScroll = styled(CardBody)`
  overflow: hidden !important;
  padding: 0 !important;
`;

export const ColCategory = styled(Col)`
  @media (max-width: 1000px) {
    margin-top: ${props => (props.notApplyMarginTop ? '0px' : '40px')};
  }
`;

export const PointsMobileContainer = styled.div`
  display: none;
  border-color: ${props => props.headerBackgroundColor};
  border-width: 0.5px;
  background-color: ${props => props.headerBackgroundColor};

  @media (max-width: 1000px) {
    display: block;
    position: relative;
    top: 50px;
  }
`;
