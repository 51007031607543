import React from 'react';
import { connect } from 'react-redux';
import { logoutUser, loginUser, setConfigs } from '../../redux/actions';
import { FooterStyled } from './style';

import opdvImage from './images/o-pdv-restaurante.png';

const Footer = props =>
  props.config ? (
    <FooterStyled>
      <span style={{ fontSize: 14, letterSpacing: 1 }}>
        Plataforma desenvolvida por:{' '}
      </span>
      <a
        target="_blank"
        href="https://opdv.com.br"
        alt="Sistema para restaurante OPDV"
        rel="noopener noreferrer"
      >
        <img src={opdvImage} width="150" alt="OPDV" />
      </a>
    </FooterStyled>
  ) : null;

const mapStateToProps = state => {
  const {
    config: { config, categories },
    user: { user },
  } = state;
  return { user, config, categories };
};
export default connect(mapStateToProps, { logoutUser, loginUser, setConfigs })(
  Footer
);
