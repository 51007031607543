import React, { useState, useMemo } from 'react';
import { Input as InputBootstrap, Label, Container } from './style';

export const Input = ({
  Component,
  label,
  value,
  onChange,
  disableAnimationLabel,
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const isActive = useMemo(
    () => isFocused || isFilled || disableAnimationLabel,
    [isFocused, isFilled, disableAnimationLabel]
  );
  const props = {
    value,
    onFocus: () => setIsFocused(true),
    onBlur: () => setIsFocused(false),
    onChange: val => {
      if (val.target.value.length > 0) {
        setIsFilled(true);
      } else {
        setIsFilled(false);
      }
      onChange(val);
    },
    ...rest,
  };

  return (
    <Container>
      <Label isActive={isActive}>{label}</Label>
      {Component ? (
        <Component {...props}>
          {inputProps => <InputBootstrap {...inputProps} />}
        </Component>
      ) : (
        <InputBootstrap {...props} />
      )}
    </Container>
  );
};
