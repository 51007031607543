import React, { useState } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  FormText,
  Col,
} from 'reactstrap';
import { connect } from 'react-redux';
import { Button } from '../button/style';
import { PointsContainer } from './style';
import { config } from '../../config';
import { getUniqueListBy, toBlr } from '../../util';
import SelectCategory from '../selectCategory';
import Loading from '../loading';

const PointsBase = props => {
  const { redeem, redeemOptionsFiltered, cart } = props;
  const [modalIsOpen, setModalOpenStatus] = useState(false);
  if (!redeem || redeem.message || redeem.status === false) return null;
  const cartRedeemDiscount = cart.products.find(
    ({ redeemId, price }) => redeemId
  );
  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        toggle={() => setModalOpenStatus(prevState => !prevState)}
      >
        <ModalHeader>Resgate de Pontos</ModalHeader>
        <ModalBody>
          <div style={{ display: `${props.loadingModal ? 'none' : 'block'}` }}>
            <SelectCategory
              value={props.categoryToFilterRedeem}
              onChange={props.categoryOnChange}
              token={props.token}
              optionsIds={redeem.options.map(option => option.categoryId)}
            />
          </div>
          {props.loadingModal ? (
            <Loading />
          ) : (
            <>
              {redeem.loyaltyPointsTerms && (
                <div>
                  <span>
                    <b>Regras:</b>{' '}
                    <a href={redeem.loyaltyPointsTerms} target="_new">
                      Clique para abrir
                    </a>
                  </span>
                  <hr />
                </div>
              )}
              <div>
                {redeemOptionsFiltered &&
                  getUniqueListBy(redeemOptionsFiltered, 'productId').map(
                    item => (
                      <Row
                        key={`product_card_${
                          item.productId || item.discountId
                        }_${item.categoryId}`}
                        style={{
                          borderBottom: '1px solid gray',
                          marginTop: 4,
                          alignItems: 'center',
                        }}
                      >
                        <Col xs={4} lg={2}>
                          {item.productImage && (
                            <img
                              style={{
                                width: '100%',
                                height: 'auto',
                                maxWidth: 400,
                              }}
                              src={`https://d26aylt6qzro7d.cloudfront.net/${config.id}/${item.productImage}`}
                              alt="produto do sistema o pdv da empresa OPDV"
                            />
                          )}
                          {item.discountValue && (
                            <span style={{ fontSize: '1.5em' }}>
                              {toBlr(item.discountValue)}
                            </span>
                          )}
                        </Col>
                        <Col lg={10} xs={8}>
                          <Row>
                            <Col xs={12}>
                              <h4 style={{ fontWeight: 'bold' }}>
                                {item.productName || item.discountName}
                              </h4>
                              <span style={{ fontSize: 18 }}>
                                Custo: {item.pointsToRedeem} pontos
                              </span>
                            </Col>
                            <Col xs={12}>
                              <Button
                                disabled={
                                  !item.isCanRedeem || cartRedeemDiscount
                                }
                                onClick={() => {
                                  props.addRedeemItem(item);
                                  setModalOpenStatus(false);
                                }}
                              >
                                Resgatar
                              </Button>
                              <Col xs={12} />
                              {!item.isCanRedeem && (
                                <FormText>
                                  Você ainda não tem pontos para este produto
                                </FormText>
                              )}
                              {cartRedeemDiscount && (
                                <FormText color="red">
                                  Você só pode resgatar um produto por vez
                                </FormText>
                              )}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    )
                  )}
              </div>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button outline onClick={() => setModalOpenStatus(false)}>
            Fechar
          </Button>
        </ModalFooter>
      </Modal>
      <PointsContainer {...props}>
        <h4>{redeem.totalPoints} Pontos</h4>
        <Button
          style={{ marginLeft: 8 }}
          {...props}
          onClick={() => setModalOpenStatus(true)}
        >
          Resgatar
        </Button>
      </PointsContainer>
    </>
  );
};
const mapStateToProps = state => {
  const {
    config: { config },
  } = state;
  return { config };
};
export default connect(mapStateToProps, {})(PointsBase);
