import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  CardFooter,
  Input,
  Row,
  Col,
  FormGroup,
  Label,
} from 'reactstrap';
import parsePhoneNumber from 'libphonenumber-js';
import moment from 'moment';

import { validateCpf } from '../../util';
import { saveFastCustomer } from '../../service';

const FastRegister = props => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    cpf: '',
    mainPhone: '',
    birthday: '',
  });

  const [loading, setLoading] = useState(false);

  const handleChange = name => value => {
    setFormData(prevValue => ({ ...prevValue, [name]: value }));
  };

  const saveFast = async e => {
    e.preventDefault();
    if (!validateCpf(formData.cpf)) {
      toast.warn('Você precisa informar um CPF válido');
      return;
    }
    const phoneNumber = parsePhoneNumber(formData.mainPhone, 'BR');
    if (!phoneNumber.isValid()) {
      toast.warn('Você precisa informar um telefone válido');
      return;
    }
    const birthDate = moment(formData.birthday, 'DD/MM/YYYY');
    if (!birthDate.isValid()) {
      toast.warn('Você precisa informar uma data de nascimento válida');
      return;
    }
    if (birthDate.get('year') < 1900) {
      toast.warn('Você precisa informar uma data de nascimento válida');
      return;
    }
    if (birthDate.get('year') >= moment().format('YYYYY')) {
      toast.warn('Você precisa informar uma data de nascimento válida');
      return;
    }
    console.log('birthDate month: ', birthDate.get('month'));
    setLoading(true);
    await saveFastCustomer(formData);
    setLoading(false);
    toast.success('Cadastro realizado com sucesso!', { delay: 10000 });
  };
  if (!props.config) {
    return null;
  }
  return (
    <form onSubmit={saveFast}>
      <Card>
        <CardHeader>Cadastro Rápido</CardHeader>
        <CardBody>
          <Row>
            <Col lg={6} xs={12}>
              <FormGroup>
                <Label>Nome *</Label>
                <Input
                  name="name"
                  required
                  disabled={loading}
                  value={formData.name}
                  onChange={({ target: { value } }) => {
                    handleChange('name')(value);
                  }}
                />
              </FormGroup>
            </Col>
            <Col lg={6} xs={12}>
              <FormGroup>
                <Label>Email *</Label>
                <Input
                  placeholder="e-mail"
                  type="email"
                  name="email"
                  disabled={loading}
                  value={formData.email}
                  required
                  onChange={({ target: { value } }) =>
                    handleChange('email')(value)
                  }
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg={6} xs={12}>
              <FormGroup>
                <Label>CPF *</Label>
                <InputMask
                  mask="999.999.999-99"
                  required
                  disabled={loading}
                  name="cpf"
                  value={formData.cpf}
                  onChange={({ target: { value } }) => {
                    handleChange('cpf')(value);
                  }}
                >
                  {inputProps => (
                    <Input
                      {...inputProps}
                      placeholder="Informe seu cpf"
                      required
                    />
                  )}
                </InputMask>
              </FormGroup>
            </Col>
            <Col lg={6} xs={12}>
              <FormGroup>
                <Label>Telefone</Label>
                <InputMask
                  disabled={loading}
                  mask="(99)9.9999 9999"
                  required
                  name="mainPhone"
                  value={formData.mainPhone}
                  onChange={({ target: { value } }) => {
                    handleChange('mainPhone')(value);
                  }}
                >
                  {inputProps => (
                    <Input
                      placeholder="(XX) X XXXX XXXX"
                      {...inputProps}
                      required
                    />
                  )}
                </InputMask>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg={6} xs={12}>
              <FormGroup>
                <Label>Data de Nascimento *</Label>
                <InputMask
                  mask="99/99/9999"
                  required
                  disabled={loading}
                  name="birthday"
                  value={formData.birthday}
                  onChange={({ target: { value } }) => {
                    handleChange('birthday')(value);
                  }}
                >
                  {inputProps => (
                    <Input placeholder="DD/MM/YYYY" {...inputProps} required />
                  )}
                </InputMask>
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <Button disabled={loading} type="submit">
            {loading ? 'Cadastrando...' : 'Cadastrar'}
          </Button>
        </CardFooter>
      </Card>
    </form>
  );
};

const mapStateToProps = state => {
  const {
    config: { config },
  } = state;
  return { config };
};
export default connect(mapStateToProps, {})(FastRegister);
