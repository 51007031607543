import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  height: 100%;
  z-index: 20;
  background-color: white;
  top: 0;
  transition: 0.3s ease-in-out;
  left: ${props => (props.isOpen ? 'calc(100% - 457px)' : '100%')};
  visibility: ${props => (props.isOpen ? 'visible' : 'hidden')};
  box-shadow: 4px 4px 16px rgb(0 0 0 / 10%);

  @media (max-width: 1000px) {
    left: 0;
    top: ${props => (props.isOpen ? 'calc(100% - 457px)' : '100%')};
    width: 100%;
  }
`;

export const Content = styled.div`
  padding: 50px 70px;
  height: 100%;

  @media (max-width: 1000px) {
    padding: 40px;
    height: auto;
  }
`;

export const ListContainer = styled.ul`
  list-style: none;
  display: flex;
  width: 100%;
  padding: 0 10px;
`;

export const ListItem = styled.li`
  width: 50%;
  button {
    padding: 15px 50px;
    outline: none;
    font-size: 16px;
    font-weight: 500;
    transition: 0.3s;
    border-bottom: ${props =>
      props.isActive
        ? `2px solid ${props.mainColor}`
        : '2px solid transparent'};
    color: ${props => (props.isActive ? props.mainColor : '')};
  }

  @media (max-width: 1000px) {
    button {
      padding: 15px 30px;
    }
  }
`;

export const AboutTab = styled.div`
  text-align: left;
  padding: 20px 0;
  > h1 {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
  }

  h2 {
    font-size: 16px;
    font-weight: 500;
  }

  p,
  span {
    display: block;
    font-weight: 100;
    font-size: 14px;
    font-style: italic;
  }

  @media (max-width: 1000px) {
    height: 250px;
    overflow-y: auto;
  }
`;

export const HoursTab = styled.div`
  padding: 15px 0;
  text-align: left;

  > div {
    display: flex;
    flex-direction: row;
    margin: 15px 0;
  }

  .weekday {
    align-self: center;
    flex: 3;
  }

  .hoursday {
    flex: 5;
  }

  @media (max-width: 1000px) {
    height: 250px;
    overflow-y: auto;

    .weekday {
      flex: 3;
    }

    .hoursday {
      flex: 8;
    }
  }
`;
