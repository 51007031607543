import React from "react";

const Loading = ({ message, messageColor = "black" }) => (
  <div
    style={{
      justifyContent: "center",
      alignContent: "center",
      width: "100%",
      paddingLeft: "0%",
      textAlign: "center",
      height: "100%",
    }}
  >
    <img
      alt=" "
      className="loadingAnimation"
      style={{
        height: "20%",
        width: "20%",
        fill: "white",
      }}
      src={`${process.env.PUBLIC_URL}/loading.svg`}
    />

    <span
      style={{
        marginTop: 10,
        float: "left",
        width: "100%",
        color: messageColor,
        textAlign: "center",
      }}
    >
      {message}
    </span>
  </div>
);

export default Loading;
