import styled from 'styled-components';

export const Container = styled.div`
  opacity: ${props => (props.isOpen ? 1 : 0)};
  visibility: ${props => (props.isOpen ? 'visible' : 'hidden')};
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${props => (props.isOpen ? 20 : 0)};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px 10px 10px;
    background-color: white;
    border-radius: 10px;
    transition: 0.3s;
    transform: ${props => (props.isOpen ? 'scale(1)' : 'scale(0)')};
    > p {
      margin: 5px 0;
      text-align: center;
      font-weight: 500;
      @media (max-width: 600px) {
        font-size: 14px;
      }
    }
    > img {
      max-height: 90vh;
      max-width: 95vw;
    }
  }
`;
