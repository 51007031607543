import styled from 'styled-components';

export const FooterStyled = styled.footer`
  width: 100%;
  height: 150px;
  box-shadow: 4px 4px 16px rgb(0 0 0 / 10%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 1000px) {
    display: none;
  }
`;
