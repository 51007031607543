import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  FormText,
  CardBody,
  CardFooter,
  Modal,
  ModalBody,
  ModalFooter,
  CardHeader,
} from 'reactstrap';
import InputMask from 'react-input-mask';
import {
  createAccount,
  verifyAccount,
  fetchUserDetail,
  doSendSmsAgain,
} from '../../service';
import { storeUser, validateCpf } from '../../util';
import { loginUser } from '../../redux/actions';

import { CreateAccountCard } from './style';
import { Button } from '../../components/button/style';
import { Input } from '../../components/input';

class CreateAccount extends Component {
  state = {
    email: '',
    mainPhone: '',
    cpf: '',
    timeToStart: 30,
    password: '',
    name: '',
    loading: false,
    validateCodeStep: false,
  };

  componentDidMount() {
    this.interval = null;
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  startTimer = () => {
    if (this.interval !== null) return;

    this.setState(
      { timeToStart: 30 },
      () =>
        (this.interval = setInterval(() => {
          const { timeToStart } = this.state;
          if (timeToStart - 1 >= 0)
            this.setState({ timeToStart: timeToStart - 1 });
          else {
            clearInterval(this.interval);
            this.interval = null;
          }
        }, 1000))
    );
  };

  createAccount = async e => {
    e.preventDefault();
    const { config } = this.props;
    const { name, email, password, cpf, mainPhone } = this.state;
    if (
      (config.cpfRequired === 'YES' && !validateCpf(cpf)) ||
      (cpf && cpf.trim() !== '' && !validateCpf(cpf))
    ) {
      alert('o CPF não é valido.');
      return;
    }
    this.setState({ loading: true });
    const customer = {
      name,
      email,
      password,
      cpf: cpf.replace(/\D/g, ''),
      mainPhone: mainPhone.replace(/\D/g, ''),
    };
    try {
      const callback = await createAccount(customer);
      if (!callback.error) {
        this.setState({
          token: callback.token,
          validateCodeStep: true,
          phoneToValidate: mainPhone.replace(/\D/g, ''),
        });
        this.startTimer();
      } else {
        alert(callback.error);
      }
    } catch (e) {
      console.log('e', e);
    }
    this.setState({ loading: false });
  };

  sendAgain = async () => {
    this.startTimer();
    const { token: validateToken } = this.state;
    await doSendSmsAgain({ validateToken });
  };

  verifyCode = async e => {
    e.preventDefault();

    const { code, token } = this.state;
    try {
      const verified = await verifyAccount({ code, token });
      if (verified.error) {
        alert(verified.error);
        this.setState({ loading: false });
        return;
      }
      const completedUser = await fetchUserDetail({ token: verified.token });
      storeUser({ ...completedUser, token: verified.token });
      this.props.loginUser({ ...completedUser, token: verified.token });
      this.props.history.push(`/${process.env.REACT_APP_PROJECT}`);
    } catch (e) {}
    this.setState({ loading: false });
  };

  render() {
    const { timeToStart } = this.state;
    const { config } = this.props;
    if (!config) {
      return null;
    }

    return (
      <div>
        <Modal size="sm" isOpen={this.state.validateCodeStep}>
          <form onSubmit={this.verifyCode}>
            <ModalBody>
              <Row>
                <Col lg={6} sm={12} md={6}>
                  <Input
                    disabled
                    required
                    value={this.state.mainPhone}
                    label="Telefone"
                    disableAnimationLabel
                  />
                </Col>
                <Col lg={6} sm={12} md={6} style={{ color: 'black' }}>
                  <Input
                    required
                    maxLength={6}
                    type="number"
                    value={this.state.code}
                    onChange={({ target: { value } }) =>
                      this.setState({ code: value })
                    }
                    label="Código"
                  />
                  <FormText style={{ marginTop: -10 }}>
                    O código foi enviado para seu{' '}
                    <span style={{ color: '#25D366' }}>WhatsApp</span>, caso não
                    tenha recebido, você pode tentar o envio por{' '}
                    <span style={{ color: 'blue' }}>SMS</span>.
                  </FormText>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter style={{ flexDirection: 'column' }}>
              <Row style={{ width: '100%' }}>
                <div style={{ marginLeft: 15 }}>
                  <Button
                    outline
                    onClick={this.sendAgain}
                    color="primary"
                    disabled={timeToStart !== 0}
                  >
                    {timeToStart !== 0
                      ? `Tentar por SMS em: 00:${`${timeToStart}`.padStart(
                          2,
                          0
                        )}`
                      : 'Enviar SMS'}
                  </Button>
                </div>
                <div style={{ marginLeft: 'auto', marginRight: 15 }}>
                  <Button
                    color="primary"
                    type="submit"
                    style={{
                      appearance: 'initial',
                      WebkitAppearance: 'initial',
                    }}
                  >
                    Verificar Código
                  </Button>
                  <Button
                    color="danger"
                    style={{
                      appearance: 'initial',
                      WebkitAppearance: 'initial',
                      marginLeft: 10,
                    }}
                    onClick={() => this.setState({ validateCodeStep: false })}
                  >
                    Cancelar
                  </Button>
                </div>
              </Row>
            </ModalFooter>
          </form>
        </Modal>

        <form onSubmit={this.createAccount}>
          <CreateAccountCard>
            <CardHeader>Faça seu cadastro</CardHeader>
            <CardBody>
              <Row>
                <Col lg={6} sm={12}>
                  <Input
                    name="name"
                    label="Nome"
                    required
                    onChange={({ target: { value } }) =>
                      this.setState({ name: value })
                    }
                  />
                </Col>
                <Col lg={6} sm={12}>
                  <Input
                    label="E-mail"
                    type="email"
                    name="email"
                    required
                    value={this.state.email}
                    onChange={({ target: { value } }) =>
                      this.setState({ email: value })
                    }
                  />
                </Col>
                <Col lg={6} sm={12}>
                  <Input
                    Component={InputMask}
                    label="CPF"
                    mask="999.999.999-99"
                    value={this.state.cpf}
                    required={config.cpfRequired === 'YES'}
                    name="cpf"
                    onChange={({ target: { value } }) =>
                      this.setState({ cpf: value })
                    }
                  />
                </Col>
                <Col lg={6} sm={12}>
                  <Input
                    type="password"
                    label="Senha"
                    required
                    name="password"
                    onChange={({ target: { value } }) =>
                      this.setState({ password: value })
                    }
                  />
                </Col>
                <Col lg={6} sm={12}>
                  <Input
                    Component={InputMask}
                    mask="(99) 99999-9999"
                    required
                    name="mainPhone"
                    value={this.state.mainPhone}
                    onChange={({ target: { value } }) =>
                      this.setState({ mainPhone: value })
                    }
                    label="Telefone"
                  />
                  <FormText style={{ marginTop: -13 }}>
                    Um código SMS será enviado para este número para confirmar
                    seu cadastro.
                  </FormText>
                </Col>
              </Row>
              <Row style={{ marginTop: 40 }}>
                <Col xs={12}>
                  <Button
                    color="primary"
                    disabled={this.state.loading}
                    type="submit"
                    style={{
                      width: '40%',
                      appearance: 'initial',
                      WebkitAppearance: 'initial',
                      fontSize: 12,
                    }}
                  >
                    {this.state.loading ? 'Criando conta..' : 'Criar Conta'}
                  </Button>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <Row style={{ maringTop: 8 }}>
                <Col xs={12} style={{ textAlign: 'center' }}>
                  <Button
                    color="primary"
                    outline
                    onClick={() =>
                      this.props.history.push(
                        `/${process.env.REACT_APP_PROJECT}/login`
                      )
                    }
                    style={{ fontSize: 12, border: 0 }}
                  >
                    Voltar para Login
                  </Button>
                </Col>
              </Row>
            </CardFooter>
          </CreateAccountCard>
        </form>
      </div>
    );
  }
}
const mapStateToProps = state => {
  const {
    config: { config },
  } = state;
  return { config };
};

export default connect(mapStateToProps, {
  loginUser,
})(CreateAccount);
