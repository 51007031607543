import React, { Fragment } from 'react';
import { Row, Col } from 'reactstrap';
import { FaMinusCircle, FaPlusCircle } from 'react-icons/fa';
import { Button } from '../button/style';
import CartItem from './item';
import { ProductCartLabel, ProductCartPrice } from './style';
import { calcProductTotalValue } from '../../util';

const CartBody = ({
  cart,
  subtractProductFromCart,
  sumProductFromCart,
  editAction,
  removeAction,
}) => (
  <>
    {cart.products.map(product => (
      <Fragment key={`${product.cartId}`}>
        <Row>
          <Col xs={8} lg={6}>
            <Row>
              <Col xs={12}>
                <div
                  style={{
                    textAlign: 'left',
                    fontSize: '0.8em',
                    fontWeight: 'bold',
                    wordBreak: 'initial',
                  }}
                >
                  <span style={{ marginRight: '0.5em' }}>
                    {product.quantity}x
                  </span>
                  {product.name}
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={4} lg={6}>
            {!product.redeemId && (
              <Row>
                <Col>
                  {subtractProductFromCart && (
                    <FaMinusCircle
                      color="red"
                      size={24}
                      style={{ cursor: 'pointer' }}
                      onClick={() => subtractProductFromCart(product)}
                    />
                  )}
                  <span style={{ margin: '0.5em' }}>{product.quantity}</span>
                  {sumProductFromCart && (
                    <FaPlusCircle
                      color="green"
                      size={24}
                      style={{ cursor: 'pointer' }}
                      onClick={() => sumProductFromCart(product)}
                    />
                  )}
                </Col>
              </Row>
            )}
            {product.redeemId && !editAction && (
              <FaMinusCircle
                color="red"
                size={24}
                style={{ cursor: 'pointer' }}
                onClick={() => subtractProductFromCart(product)}
              />
            )}
          </Col>
          {product.observation && product.observation !== '' && (
            <Col
              xs={12}
              style={{ fontSize: '0.7em', textAlign: 'left' }}
            >{`* ${product.observation}`}</Col>
          )}
        </Row>
        <Row>
          <CartItem multiple={product.wizardSteps} />
          <Col xs={6}>
            <ProductCartLabel>Total unitário:</ProductCartLabel>
          </Col>
          <Col xs={6}>
            <ProductCartPrice>
              {calcProductTotalValue({
                cartProduct: { ...product },
                format: true,
                multiplyQuantity: false,
              })}
            </ProductCartPrice>
          </Col>
          <Col xs={6}>
            <ProductCartLabel>Total Item:</ProductCartLabel>
          </Col>
          <Col xs={6}>
            <ProductCartPrice>
              {calcProductTotalValue({
                cartProduct: { ...product },
                format: true,
                multiplyQuantity: true,
              })}
            </ProductCartPrice>
          </Col>
        </Row>

        <Row>
          <Col xs={6}>
            {product.price < 0 && (
              <ProductCartLabel>Valor do desconto:</ProductCartLabel>
            )}
          </Col>
        </Row>
        <Row style={{ marginTop: 4 }}>
          {editAction && !product.redeemId && (
            <Col xs={4}>
              <Button outline onClick={() => editAction(product)}>
                Editar
              </Button>
            </Col>
          )}
          {removeAction && (
            <Col xs={8} style={{ textAlign: 'left' }}>
              <Button
                color="danger"
                outline
                onClick={() => removeAction(product)}
              >
                Remover
              </Button>
            </Col>
          )}
          <Col xs={12}>
            <hr />
          </Col>
        </Row>
      </Fragment>
    ))}
  </>
);
export default CartBody;
