/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { FormGroup, Label } from 'reactstrap';
import { listProductCategories } from '../../service';

const SelectCategory = ({ token, value, optionsIds, onChange, ...rest }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    async function getCategories() {
      try {
        const optionsFetched = await listProductCategories({
          token,
        });
        if (optionsIds) {
          const uniqOptionsIds = [...new Set(optionsIds)];
          const optionsToSet = optionsFetched
            .filter(option => uniqOptionsIds.includes(option.id))
            .map(option => ({
              value: option.id,
              label: option.name,
            }));
          optionsToSet.unshift({ value: null, label: 'Todas' });
          setOptions(optionsToSet);
        } else {
          optionsFetched.unshift({ value: null, label: 'Todas' });
          setOptions(
            optionsFetched.map(option => ({
              value: option.id,
              label: option.name,
            }))
          );
        }
      } catch (error) {
        console.log(error);
      }
    }

    getCategories();
  }, []);

  if (options.length === 0) {
    return <div />;
  }
  return (
    <FormGroup>
      <Label>Filtrar por categoria</Label>
      <Select
        placeholder="Selecione.."
        options={options}
        value={value}
        onChange={onChange}
        {...rest}
      />
    </FormGroup>
  );
};

export default SelectCategory;
