import React from 'react';
import { Col } from 'reactstrap';
import { config } from '../../config';

const BannerView = ({
  banners,
  configColor,
  categoryIndex,
  category,
  onClick,
}) => {
  return (
    <Col
      lg={6}
      key={`banner_${categoryIndex}_${category.id}`}
      sm={12}
      md={6}
      style={{
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <div
        style={{
          width: '100%',
          boxShadow: 'rgba(0, 0, 0, 0.08) 1px 1px 1px !important',
          borderRadius: 25,
          marginTop: 4,
          border: `1px solid ${configColor.bannerCardBorderColor}`,
        }}
      >
        {!banners && category.image && (
          <img
            alt={`${category.name} faz parte de pdv OPDV que é um sistem integrado com ifood e Uber Eats - OPDV`}
            style={{ width: '90%' }}
            src={`https://d26aylt6qzro7d.cloudfront.net/${config.id}/${category.image}`}
          />
        )}
      </div>
    </Col>
  );
};

export const BannerSimpleView = ({ campaing, bannerIndex, onClick }) => (
  <Col
    lg={12}
    key={`banner_view_${bannerIndex}`}
    sm={12}
    md={12}
    onClick={onClick}
    style={{
      cursor: 'pointer',
    }}
  >
    <div
      style={{
        width: '100%',
      }}
    >
      {campaing && (
        <img
          alt={`${campaing.name} é uma camapnha que faz parte de pdv OPDV. O sistem integrado com ifood e Uber Eats - OPDV`}
          style={{ width: '100%' }}
          src={`https://d26aylt6qzro7d.cloudfront.net/${config.id}/${campaing.bannerApp}`}
        />
      )}
    </div>
  </Col>
);
export default BannerView;
