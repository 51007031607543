import moment from 'moment';
import { calcTotalCart } from '.';

const generateSubItems = wizardSteps => {
  const subItems = [];
  wizardSteps.forEach(variation => {
    variation.options
      .filter(
        ({ quantity, id }) =>
          quantity ||
          (variation.formData[id] && variation.formData[id].checked === true)
      )
      .forEach(value => {
        const subItem = {
          quantity: value.quantity || 1,
          discount: 0,
          type: variation.type,
          product: {
            id: value.id,
            name: value.name,
            price: value.price || 0,
          },
        };
        subItems.push(subItem);
      });
  });
  return subItems;
};

export const createOrder = ({
  cartProducts,
  address,
  customer,
  storeId,
  customerId,
  promocodeId,
  change,
  discount,
  timeOfDay,
  payment,
  isTakeAway,
  deliveryFee,
  scheduled = 0,
  sourceId,
  externalId,
  dayOfWeek,
  observation,
}) => {
  let totalDiscount = 0;
  if (discount) {
    totalDiscount += parseInt(discount, 10);
  }
  const cartRedeemDiscount = cartProducts.find(({ redeemId }) => redeemId);
  if (cartRedeemDiscount) {
    totalDiscount += parseInt(cartRedeemDiscount.price, 10) * -1;
  }
  const cartProductsWithoutRedeem = cartProducts.filter(
    ({ redeemId, price }) => !redeemId || price === 0
  );

  const output = {
    customer,
    discount: totalDiscount,
    customerId,
    externalId,
    createdDate: scheduled
      ? dayOfWeek &&
        timeOfDay &&
        moment(`${dayOfWeek}/${timeOfDay.format('HH')}:00`, 'DD/MM/HH:mm')
      : null,
    scheduled,
    storeId,
    redeemOptionId: cartRedeemDiscount ? cartRedeemDiscount.redeemId : null,
    discountId: -1,
    deliveryFee: deliveryFee || 0,
    promocodeId,
    address,
    observation: process.env.REACT_APP_TIME_TO_SCHEDULED
      ? `PEDIDO AGENDADO PARA DIA ${dayOfWeek}, ${observation}`
      : observation,
    items: cartProductsWithoutRedeem.map((product, index) => ({
      sequence: index + 1,
      quantity: product.quantity,
      observation: product.observation,
      discount: 0,
      type: 0,
      product: {
        id: product.id,
        name: product.name,
        price: product.price,
      },
      subItems: generateSubItems(product.wizardSteps),
    })),
    orderType: isTakeAway ? 0 : 1, // 1 == delivery || 0 == takeaway
    sourceId: sourceId || 112,
    status: 20,
    paymentStatus: 0,
    productTotal: calcTotalCart({
      cartProducts: cartProductsWithoutRedeem,
      deliverFee: 0,
      format: false,
    }),
    total: calcTotalCart({
      cartProducts: cartProductsWithoutRedeem,
      discount: totalDiscount,
      deliveryFee,
      format: false,
    }),
    payments: [
      {
        value:
          payment.type === 0
            ? change ||
              calcTotalCart({
                discount: totalDiscount,
                cartProducts: cartProductsWithoutRedeem,
                deliveryFee,
                format: false,
              })
            : calcTotalCart({
                discount: totalDiscount,
                cartProducts: cartProductsWithoutRedeem,
                deliveryFee,
                format: false,
              }),
        paymentMethod: {
          id: payment.id,
          name: payment.methodKey,
        },
      },
    ],
  };
  return output;
};
