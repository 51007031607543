import styled from 'styled-components';

export const PointsContainer = styled.div`
  display: ${props => (props.hideDesktop ? 'none' : 'flex')};
  justify-content: space-around;
  color: ${props => props.config.textBaseColor};
  background-color: ${props => props.config.headerBackgroundColor};
  padding: 12px;
  flex-direction: row;
  align-items: center;
  position: relative;
  width: 100%;
  z-index: 11;
`;
