import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, CardBody, CardFooter, CardHeader } from 'reactstrap';
import { LoginCard } from './style';
import { callLogin, fetchUserDetail } from '../../service';
import { storeUser } from '../../util';
import { loginUser } from '../../redux/actions';
import { Button } from '../../components/button/style';
import { Input } from '../../components/input';

class LoginPage extends Component {
  state = {
    loading: false,
    email: '',
    password: '',
    msgError: '',
  };

  doLogin = async e => {
    e.preventDefault();
    this.setState({ loading: true, msgError: '' });
    const user = await callLogin({
      username: this.state.email,
      password: this.state.password,
    });
    if (!user || user.error) {
      this.setState({
        loading: false,
        msgError: user ? user.error : 'Falha na conexão de internet',
      });
      return;
    }
    const customer = await fetchUserDetail({ token: user.token });
    this.props.loginUser({ ...customer, token: user.token });
    storeUser({ ...customer, token: user.token });
    this.setState({ loading: false }, () =>
      this.props.history.push(`/${process.env.REACT_APP_PROJECT}`)
    );
  };

  render() {
    const { loading, msgError } = this.state;
    if (!this.props.config) {
      return null;
    }
    return (
      <LoginCard>
        <CardHeader>Informe seu e-mail e senha</CardHeader>
        <CardBody style={{ paddingBottom: 'calc(1.25rem + 15px)' }}>
          <Row style={{ justifyContent: 'center' }}>
            <Col style={{ maxWidth: 400 }}>
              <form onSubmit={this.doLogin}>
                <Row>
                  <Col xs={12}>
                    <Input
                      type="email"
                      name="email"
                      required
                      value={this.state.email}
                      onChange={({ target: { value } }) =>
                        this.setState({ email: value })
                      }
                      label="E-mail"
                    />
                  </Col>
                  <Col xs={12}>
                    <Input
                      type="password"
                      name="password"
                      required
                      value={this.state.password}
                      onChange={({ target: { value } }) =>
                        this.setState({ password: value })
                      }
                      label="Senha"
                    />
                  </Col>
                </Row>
                <Row style={{ margin: 4 }}>
                  <Col xs={12} style={{ color: 'red' }}>
                    {msgError}
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <Button
                      color="primary"
                      type="submit"
                      style={{ fontSize: 12, width: '55%' }}
                      disabled={loading}
                    >
                      {!loading ? 'Entrar' : 'Logando...'}
                    </Button>
                  </Col>
                </Row>
                <Row style={{ marginTop: 10 }}>
                  <Col xs={12}>
                    <Button
                      color="primary"
                      outline
                      style={{ fontSize: 12, width: '55%' }}
                      onClick={() =>
                        this.props.history.push(
                          `/${process.env.REACT_APP_PROJECT}/create`
                        )
                      }
                    >
                      Criar Conta
                    </Button>
                  </Col>
                </Row>
              </form>
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <Button
            color="primary"
            outline
            onClick={() =>
              this.props.history.push(
                `/${process.env.REACT_APP_PROJECT}/password`
              )
            }
            style={{ fontSize: 12, border: 0 }}
          >
            Esqueci minha Senha
          </Button>
        </CardFooter>
      </LoginCard>
    );
  }
}
const mapStateToProps = state => {
  const {
    config: { config },
  } = state;
  return { config };
};
export default connect(mapStateToProps, {
  loginUser,
})(LoginPage);
