import React, { Fragment } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  ModalFooter,
  Button,
  FormText,
} from "reactstrap";
import InputMask from "react-input-mask";
import Loading from "../loading";
import { formatCep, getSelectedAddress, getUser } from "../../util";
import { fetchAddressInformation, fetchUserDetail } from "../../service";
import { Logo } from "../../views/Header/style";
import { connect } from "react-redux";

class AddressSelectionModal extends React.Component {
  state = { addressInformation: {}, loading: false, customer: null };

  async componentDidMount() {
    const user = getUser();
    const selectedAddress = getSelectedAddress();
    if (user) {
      this.setState({ loading: true });
      const customer = await fetchUserDetail({ token: user.token });
      this.setState({ customer, loading: false });
    }
    if (selectedAddress) {
      this.setState({ cep: formatCep(selectedAddress.zip) });
      await this.loadAddressFromCep(selectedAddress.zip);
    }
  }

  loadAddressFromCep = async (zipCode) => {
    this.setState({ loadingCep: true });
    const addressInformation = await fetchAddressInformation(zipCode)
      .then((addressInformation) => ({
        ...addressInformation,
        canEditStreet:
          !addressInformation.street || addressInformation.street.trim() === "",
        number: getSelectedAddress()?.number || "",
        complement: getSelectedAddress()?.complement || "",
      }))
      .catch(() => ({
        error: "Cep não encontrado",
      }));

    this.setState({ loadingCep: false, addressInformation });
  };

  onChange = (field) => async (value) => {
    this.setState({ [field]: value });
    if (
      field === "cep" &&
      value.replace("_", "").replace("-", "").length === 8
    ) {
      const cep = value.replace("_", "").replace("-", "");
      this.loadAddressFromCep(cep);
    }
    if (field !== "cep") {
      this.setState({
        addressInformation: {
          ...this.state.addressInformation,
          [field]: value,
        },
      });
    }
  };
  render() {
    const { loadingCep, loading, addressInformation, customer } = this.state;
    const {
      isOpen,
      confirmAction,
      cancelAction,
      openNewAddress,
      openStoreSelection,
      config,
    } = this.props;
    return (
      <Modal isOpen={isOpen} toggle={() => {}} size="lg">
        {customer && (
          <Fragment>
            <ModalHeader>
              {!loading && customer.addressList.length > 0 ? (
                <span>Selecione um endereço.</span>
              ) : (
                <span>Nenhum endereço cadastrado.</span>
              )}
            </ModalHeader>
            <ModalBody>
              {loading && <Loading message="Carregando endereços" />}
              {!loading &&
                customer.addressList.map((address, index) => (
                  <Row key={`${address.cep}_${index}`} style={{ marginTop: 4 }}>
                    <Col xs={12}>
                      <FormGroup check style={{ textAlign: "left" }}>
                        <Label check>
                          <Input
                            type="radio"
                            checked={this.state.selectedAddressId === index}
                            style={{ width: 20, height: 20 }}
                            onChange={({ target: { value, checked } }) =>
                              confirmAction(address)
                            }
                          />
                          {"  "}
                          {address.zip} - {address.street}, {address.number}{" "}
                          {address.complement}
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                ))}
            </ModalBody>
            <ModalFooter>
              <Button outline onClick={cancelAction}>
                Cancelar
              </Button>

              <Button onClick={openNewAddress}>Novo Endereço</Button>
            </ModalFooter>
          </Fragment>
        )}
        {!customer && (
          <Fragment>
            <ModalHeader
              style={{
                alignItems: "center",
                justifyContent: "center",
                fontSize: "calc(10px + 2vmin)",
                borderBottom: `1px solid ${this.props.config.headerBottomColor}`,
                backgroundColor: this.props.config.headerBackgroundColor,
              }}
            >
              <Logo src={config.merchantLogo} onClick={() => {}} />
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col xs={12}>
                  <FormGroup>
                    <Label>
                      Informe seu CEP e faça o pedido na loja mais próxima de
                      você
                    </Label>
                    <InputMask
                      disabled={loadingCep}
                      mask="99999-999"
                      value={this.state.cep}
                      required
                      onChange={({ target: { value: cep } }) =>
                        this.onChange("cep")(cep)
                      }
                    >
                      {(inputProps) => (
                        <Input
                          {...inputProps}
                          disabled={loadingCep}
                          placeholder="CEP"
                          required
                        />
                      )}
                    </InputMask>
                  </FormGroup>
                </Col>
              </Row>
              {this.state.cep && this.state.cep.length === 9 && (
                <Row>
                  {loadingCep && (
                    <Col xs={12}>
                      <Loading message="Carregando endereço.." />
                    </Col>
                  )}
                  {!loadingCep && addressInformation.error && (
                    <Col xs={12}>
                      <span style={{ color: "red" }}>
                        {addressInformation.error}
                      </span>
                    </Col>
                  )}
                  {!loadingCep && addressInformation.zip && (
                    <Fragment>
                      <Col lg={8} sm={12}>
                        <FormGroup>
                          <Label>Endereço</Label>
                          <Input
                            value={addressInformation.street}
                            placeholder="Endereço"
                            required
                            onChange={({ target: { value: street } }) =>
                              this.onChange("street")(street)
                            }
                            disabled={!addressInformation.canEditStreet}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={4} sm={12}>
                        <FormGroup>
                          <Label>Número</Label>
                          <Input
                            value={addressInformation.number}
                            placeholder="Número"
                            required
                            onChange={({ target: { value: number } }) =>
                              this.onChange("number")(number)
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={8} sm={12}>
                        <FormGroup>
                          <Label>Complemento</Label>
                          <Input
                            value={addressInformation.complement}
                            onChange={({ target: { value: complement } }) =>
                              this.onChange("complement")(complement)
                            }
                            placeholder="Número"
                          />
                        </FormGroup>
                      </Col>
                    </Fragment>
                  )}
                </Row>
              )}
              {(!this.state.cep || this.state.cep.length < 9) && (
                <Row>
                  <Col xs={12}>
                    Caso não deseje informar o endereço, você pode selecionar
                    uma loja clicando no botão abaixo.
                  </Col>
                  <Col xs={12}>
                    <Button outline onClick={openStoreSelection}>
                      Selecionar loja
                    </Button>
                  </Col>
                </Row>
              )}
            </ModalBody>
            <ModalFooter style={{ display: "inline-grid" }}>
              <Row>
                <Col xs={12} style={{ textAlign: "right" }}>
                  {getSelectedAddress() && (
                    <Button
                      style={{ marginRight: 10 }}
                      outline
                      onClick={cancelAction}
                    >
                      Cancelar
                    </Button>
                  )}
                  <Button
                    disabled={!addressInformation.number || loadingCep}
                    onClick={() => confirmAction(addressInformation)}
                  >
                    Confirmar
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  {!addressInformation.number && (
                    <FormText>
                      Você deve preencher o seu endereço antes de continuar
                    </FormText>
                  )}
                </Col>
              </Row>
            </ModalFooter>
          </Fragment>
        )}
      </Modal>
    );
  }
}
const mapStateToProps = (state) => {
  const {
    config: { config },
  } = state;
  return { config };
};
export default connect(mapStateToProps, {})(AddressSelectionModal);
