import styled from 'styled-components';
import { Card } from 'reactstrap';

export const LoginCard = styled(Card)`
  max-width: 800px;
  margin: 20px auto;
`;

export const Title = styled.h1`
  color: #222;
  font-size: 16px;
  display: block;
  margin-bottom: 0;
`;
