import ReactPixel from "react-facebook-pixel";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga";

export const trackEvent = ({ name, data }) => {
  if (process.env.REACT_APP_GOOGLE) {
    const tagManagerArgs = {
      dataLayer: {
        ...data,
      },
      dataLayerName: name,
    };

    TagManager.dataLayer(tagManagerArgs);
  }
  if (process.env.REACT_APP_PIXEL) {
    ReactPixel.track(name, {
      ...data,
    });
  }
  if (process.env.REACT_APP_GOOGLE_ANALYTICS) {
    console.log('track event on GA')
    ReactGA.event({
      category: name,
      action: name,
      value: data,
    });
  }
};
