import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  position: fixed;
  z-index: 20;
  left: 15px;
  top: 107px;

  @media (max-width: 999px) {
    top: ${props => (props.shortHeader ? '60px' : '107px')};
    transition: top 0.3s;
  }
`;

export const Fixed = styled.div`
  position: absolute;
  bottom: 0px;
`;
