import styled from 'styled-components';

export const OrderSuccessTitle = styled.h1`
  font-weight: bold;
  font-size: 36px;
  margin-bottom: 10px;
  color: ${props => props.config?.textCategoryColor};

  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

export const OrderId = styled.p`
  font-weight: 500;
  font-size: 24px;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;
