import React from 'react';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { config } from '../../config';
import {
  ProductDescriptionList,
  ProductName,
  ProductPrice,
  ProductBox,
  ProductGridContainer,
  ProductGridName,
  ProductGridImage,
  ColProductGrid,
  ProductGridPrice,
} from './style';
import { getMinPrice } from '../../util';

const merchantId = config.id;

const ProductGrid = ({ product, configBase, showProductModal }) => (
  <ColProductGrid xs={6} md={4} lg={4} xl={3}>
    <ProductGridContainer
      config={configBase}
      onClick={showProductModal(product)}
    >
      <ProductGridImage>
        <img
          src={`https://d26aylt6qzro7d.cloudfront.net/${merchantId}/${product.image}`}
          alt="Imagem do produto"
          style={{
            objectFit: 'cover',
            width: '100%',
            height: '100%',
            borderRadius: '15px 15px 0 0',
          }}
        />
      </ProductGridImage>
      <ProductGridName>{product.name}</ProductGridName>
      <ProductGridPrice>
        {!product.price && <span>A partir de&nbsp;</span>}
        <ProductPrice>{` ${getMinPrice(product)}`}</ProductPrice>
      </ProductGridPrice>
    </ProductGridContainer>
  </ColProductGrid>
);

const ProductNormal = ({ product, configBase, showProductModal }) => (
  <Col
    xs={12}
    lg={6}
    style={{
      marginTop: 15,
    }}
  >
    <ProductBox config={configBase} onClick={showProductModal(product)}>
      <Col
        xs={5}
        style={{
          backgroundColor: 'white',
          backgroundImage: `url(https://d26aylt6qzro7d.cloudfront.net/${merchantId}/${product.image})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          borderBottomLeftRadius: 15,
          borderTopLeftRadius: 15,
        }}
      />
      <Col xs={7} rowSpan={2}>
        <Row
          style={{
            textAlign: 'left',
            display: 'flex',
            height: '100%',
            padding: 5,
            justifyContent: 'space-beteween',
          }}
        >
          <Col xs={12} style={{ overflow: 'hidden' }}>
            <ProductName>{product.name}</ProductName>
          </Col>
          <Col xs={12} style={{ width: '100%', overflow: 'hidden' }}>
            <ProductDescriptionList>
              {product.description}
            </ProductDescriptionList>
          </Col>
          <Col xs={12}>
            {!product.price && <span>A partir de </span>}
            <ProductPrice>{getMinPrice(product)}</ProductPrice>
          </Col>
        </Row>
      </Col>
    </ProductBox>
  </Col>
);

const ProductBase = ({ product, showProductModal, config: configBase }) => {
  if (configBase.displayProductsInGrid === 'YES') {
    return (
      <ProductGrid
        product={product}
        configBase={configBase}
        showProductModal={showProductModal}
      />
    );
  }
  return (
    <ProductNormal
      product={product}
      configBase={configBase}
      showProductModal={showProductModal}
    />
  );
};

const mapStateToProps = state => {
  const {
    config: { config: configBase },
  } = state;
  return { configBase };
};
export default connect(mapStateToProps, {})(ProductBase);
