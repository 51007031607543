/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import { Input as InputBootstrap } from 'reactstrap';

export const Container = styled.div`
  position: relative;
  margin: 15px 0px;
`;

export const Label = styled.span`
  user-select: none;
  pointer-events: none;
  position: absolute;
  top: ${props => (props.isActive ? '-25px' : '50%')};
  left: ${props => (props.isActive ? '0px' : '10px')};
  transform: ${props =>
    props.isActive ? 'translateY(-0%)' : 'translateY(-55%)'};
  font-size: ${props => (props.isActive ? '14px' : '16px')};

  opacity: 0.7;
  transition: 300ms;
`;

export const Input = styled(InputBootstrap)``;
