import styled from 'styled-components';
import { colorIsReadableOnBackgroundWhite } from '../../util';

export const Container = styled.div`
  position: absolute;
  top: ${props => (props.isActive ? '120%' : '200%')};
  left: -150%;
  background-color: white;
  padding: 20px;
  z-index: 15;
  width: 325px;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: 0.4s;

  color: ${props => props.color};

  opacity: ${props => (props.isActive ? 1 : 0)};
  visibility: ${props => (props.isActive ? 'visible' : 'hidden')};

  h1 {
    margin-bottom: 0;
    font-size: 24px;
    font-weight: 400;
  }
`;

export const Line = styled.div`
  font-size: 14px;
  text-align: left;

  p {
    margin-bottom: 0;
    white-space: nowrap;
  }
`;

export const Close = styled.span`
  position: absolute;
  width: 25px;
  height: 25px;
  cursor: pointer;
  top: 8px;
  left: calc(100% - 38px);
  background-color: #fff;
  padding: 1.5px;
  border-radius: 3px;
  transition: 0.3s;

  &:hover {
    background-color: ${props => props.color};
  }

  &::before,
  &::after {
    position: absolute;
    top: 2.5px;
    content: ' ';
    height: 20px;
    width: 2px;
    background-color: ${props => props.color};
    transition: 0.3s;
  }
  &:hover::before,
  &:hover::after {
    background-color: ${props =>
      colorIsReadableOnBackgroundWhite(props.color) ? '#fff' : '#000'};
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
`;
