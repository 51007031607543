import React from "react";
import { default as CurrencyInputReact } from "react-currency-input";

const CurrencyInput = props => (
  <CurrencyInputReact
    onChange={props.onChange}
    className="form-control"
    prefix="R$ "
    decimalSeparator=","
    thousandSeparator="."
    required={props.required}
    {...props}
  />
);
export default CurrencyInput;
