export const ADD_TO_CART = 'ADD_TO_CART';
export const SUM_FROM_CART = 'SUM_FROM_CART';
export const SUB_FROM_CART = 'SUB_FROM_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const RETRIEVE_CART = 'RETRIEVE_CART';
export const REPLACE_TO_CART = 'REPLACE_TO_CART';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const CLEAR_CART = 'CLEAR_CART';

export const APPLY_PERCENT = 'APPLY_PERCENT';
export const APPLY_ZERO_DELIVERY = 'APPLY_ZERO_DELIVERY';
export const ADD_REDEEM_ITEM = 'ADD_REDEEM_ITEM';

export const SET_CONFIG = 'SET_CONFIG';
export const SET_HASH = 'SET_HASH';

export const SET_CATEGORIES = 'SET_CATEGORIES';
