import { LOGIN_USER, LOGOUT_USER } from "../actionTypes";

const initialState = {
  user: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LOGIN_USER: {
      const { user } = action.payload;
      return {
        ...state,
        user
      };
    }
    case LOGOUT_USER: {
      return {
        ...initialState
      };
    }
    default:
      return state;
  }
}
