import React, { useState } from 'react';
import { CardFooter, Row, Col } from 'reactstrap';
import { FaTicketAlt } from 'react-icons/fa';
import { ProductPrice, ProductPriceRow } from './style';
import { calcTotalCart, toBlr } from '../../util';
import ModalApplyPromocode from '../modalApplyPromocode';

const CartFooter = ({
  cart,
  deliveryFee,
  applyPromoCode,
  colorPromoCode,
  hidePromocode = false,
}) => {
  const [promocodeModal, setPromocodeModal] = useState(false);
  return (
    <CardFooter>
      <ModalApplyPromocode
        open={promocodeModal}
        onToggle={() => setPromocodeModal(prevState => !prevState)}
        applyPromoCode={applyPromoCode}
        cart={cart}
      />
      {cart && (
        <>
          <Row>
            {!hidePromocode && (
              <Col xs="12" style={{ textAlign: 'center' }}>
                <button
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: 14,
                    cursor: 'pointer',
                    fontWeight: 'bold',
                    border: 0,
                    background: 'none',
                    width: '100%',
                    color: colorPromoCode || '#000',
                  }}
                  type="button"
                  onClick={() => {
                    if (cart.discount) {
                      applyPromoCode({
                        code: null,
                        percent: 0,
                        promocodeId: null,
                      });
                    } else {
                      setPromocodeModal(true);
                    }
                  }}
                >
                  <FaTicketAlt color={colorPromoCode || '#000'} />
                  <p style={{ marginBottom: 3, marginLeft: 10 }}>
                    {cart.discount
                      ? 'Remover cupom de desconto'
                      : 'Aplicar cupom de desconto'}
                  </p>
                </button>
                <hr style={{ margin: '10px 0' }} />
              </Col>
            )}
          </Row>
          <ProductPriceRow>
            <span>Subtotal: </span>
            <ProductPrice>
              {calcTotalCart({
                cartProducts: [...cart.products],
              })}
            </ProductPrice>
          </ProductPriceRow>
          {(deliveryFee > 0 || cart.zeroDelivery) && (
            <ProductPriceRow>
              <span>Frete: </span>
              <ProductPrice>{toBlr(deliveryFee)}</ProductPrice>
            </ProductPriceRow>
          )}
          {!!cart.discount && (
            <ProductPriceRow>
              <span>Desconto: </span>
              <ProductPrice>{toBlr(cart.discount)}</ProductPrice>
            </ProductPriceRow>
          )}
          <ProductPriceRow style={{ fontWeight: 500 }}>
            <span>Total: </span>
            <ProductPrice style={{ fontWeight: 500 }}>
              {calcTotalCart({
                cartProducts: [...cart.products],
                deliveryFee,
                discount: cart.discount,
              })}
            </ProductPrice>
          </ProductPriceRow>
        </>
      )}
    </CardFooter>
  );
};
export default CartFooter;
