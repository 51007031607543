import React from 'react';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { FaArrowAltCircleDown } from 'react-icons/fa';
import { IoStorefrontOutline } from 'react-icons/io5';

import { getSelectedStore, getSelectedAddress } from '../../util';
import { ButtonNoStyle } from '../../util/style';
import { Container, Fixed } from './styles';

const drawAddress = ({ openAddress, onDelivery, config }) => {
  if (!getSelectedStore()) return <div />;
  if (getSelectedStore().type.toLowerCase() === 'takeaway') {
    return (
      <ButtonNoStyle
        style={{
          display: 'flex',
          fontWeight: 'bold',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          maxWidth: '90vw',
          overflow: 'hidden',
          marginBottom: 0,
          color: config.textBaseColor,
        }}
        onClick={onDelivery}
      >
        {getSelectedStore().name}
        <FaArrowAltCircleDown
          style={{
            marginLeft: 4,
            marginTop: 4,
            color: config.textBaseColor,
          }}
        />
      </ButtonNoStyle>
    );
  }
  if (getSelectedStore().type.toLowerCase() === 'delivery') {
    return (
      <Row
        style={{ cursor: 'pointer', maxWidth: '60vw' }}
        onClick={openAddress}
      >
        <Col xs={12} style={{ display: 'flex' }}>
          <p
            style={{
              fontWeight: 'bold',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              maxWidth: '90vw',
              overflow: 'hidden',
              marginBottom: 0,
            }}
          >
            {`${getSelectedAddress().street}/${getSelectedAddress().number} ${
              getSelectedAddress().complement
            }`}
          </p>
          <FaArrowAltCircleDown
            style={{
              marginLeft: 8,
              marginTop: 4,
              color: config.textBaseColor,
            }}
          />
        </Col>
      </Row>
    );
  }
  return <div />;
};

const FixedAddress = ({
  hideAddress = false,
  onTakeAway,
  openAddress,
  config,
  onDelivery,
  shortHeader,
  modalStoreInfo,
  toggleModalStoreInfo,
}) => {
  if (!config) {
    return <div />;
  }
  return (
    <Container shortHeader={shortHeader}>
      <Fixed>
        <div style={{ display: 'flex' }}>
          {config.hasDelivery === 'YES' && (
            <ButtonNoStyle type="button" onClick={onTakeAway}>
              <h1
                style={{
                  cursor: 'pointer',
                  fontSize: 14,
                  color: config.textBaseColor,
                  fontWeight:
                    getSelectedStore() && getSelectedStore().type === 'delivery'
                      ? 'bold'
                      : 'normal',
                  borderBottom:
                    getSelectedStore() && getSelectedStore().type === 'delivery'
                      ? `1px solid ${config.headerUnderlineColor}`
                      : '0px',
                }}
              >
                Entrega
              </h1>
            </ButtonNoStyle>
          )}
          {config.hasTakeAway === 'YES' && (
            <ButtonNoStyle
              type="button"
              onClick={onDelivery}
              style={{ marginLeft: 25 }}
            >
              <h1
                style={{
                  cursor: 'pointer',
                  color: config.textBaseColor,
                  fontSize: 14,
                  fontWeight:
                    getSelectedStore() &&
                    getSelectedStore().type.toLowerCase() === 'takeaway'
                      ? 'bold'
                      : 'normal',
                  borderBottom:
                    getSelectedStore() &&
                    getSelectedStore().type.toLowerCase() === 'takeaway'
                      ? `1px solid ${config.headerUnderlineColor}`
                      : '0px',
                }}
              >
                Retirada
              </h1>
            </ButtonNoStyle>
          )}
        </div>
        {!hideAddress && (
          <div
            style={{
              display: 'flex',
              overflow: 'hidden',
              fontSize: 14,
              textAlign: 'left',
              color: config.textBaseColor,
            }}
          >
            {drawAddress({ openAddress, onDelivery, config })}
            {config.displayStoreInfo === 'YES' && (
              <>
                <span style={{ margin: '0 10px' }}>|</span>
                <ButtonNoStyle
                  style={{
                    color: config.textBaseColor,
                    fontWeight: 'bold',
                  }}
                  onClick={toggleModalStoreInfo}
                >
                  <span>
                    Ver loja{' '}
                    <IoStorefrontOutline
                      style={{
                        color: config.textBaseColor,
                        marginLeft: 4,
                      }}
                      size={18}
                    />
                  </span>
                </ButtonNoStyle>
              </>
            )}
          </div>
        )}
      </Fixed>
    </Container>
  );
};

const mapStateToProps = state => {
  const {
    config: { config },
  } = state;
  return { config };
};
export default connect(mapStateToProps, {})(FixedAddress);
